import { DashboardInput, DashboardQuery } from 'API';
import { dashboardQuery } from 'graphql/queries';
import Http from './http';

/**
 * Fetches the dashboard data via the dashboard GraphQL query.
 * @param input - dashboard query input.
 * @returns Dashboard data.
 */
export const getDashboard = async (input: DashboardInput) => {
  const response = await Http.handleGraphqlOperation<DashboardQuery>(dashboardQuery, { input });
  return Http.processGraphqlResponse(response.dashboard, 'Dashboard');
};
