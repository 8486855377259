import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import { useLabStore } from 'stores/useLabStore';
import { LABS } from '../../../shared/constants/labs.constants';

/**
 * Lab selector component.
 * @returns JSX.Element LabSelector component
 */
const LabSelector: React.FC = () => {
  const selected = useLabStore(state => state.selectedLab);
  const setSelected = useLabStore(state => state.setSelectedLab);

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="m-2 relative">
            <Listbox.Button className="relative w-full text-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <span className="flex items-center">
                <img src={selected.logo} alt="" className="flex-shrink-0 h-6 w-6" />
                <span className="ml-3 block truncate">{selected.name}</span>
              </span>
              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-78 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              >
                {LABS.map(lab => (
                  <Listbox.Option
                    key={lab.id}
                    className={({ active }) =>
                      classNames('cursor-default select-none relative py-2 pl-3 pr-9', {
                        'text-white bg-indigo-600': active,
                        'text-gray-900': !active,
                      })
                    }
                    value={lab}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <img src={lab.logo} alt="" className="flex-shrink-0 h-6 w-6" />
                          <span
                            className={classNames('ml-3 block truncate', {
                              'font-semibold': selected,
                              'font-normal': !selected,
                            })}
                          >
                            {lab.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames('absolute inset-y-0 right-0 flex items-center pr-4', {
                              'text-white': active,
                              'text-indigo-600': !active,
                            })}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default LabSelector;
