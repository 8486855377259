import ModuleContainer from 'components/common/ModuleContainer';
import ModuleHeader from 'components/common/ModuleHeader';
import StartTask from 'components/dashboard/StartTask';
import TaskHistory from 'components/dashboard/TaskHistory';
import { useAuth } from 'providers/AuthProvider';
import { getDashboard } from 'shared/api/dashboard.api';
import { useQueryFetcher } from 'shared/hooks/useQueryFetcher';
import { useLabStore } from 'stores/useLabStore';

/**
 * Renders a central dashboard page, where user can perform multiple actions
 * presents CTA for new case creation {@link StartTask}, invoicing and a Case History block {@link TaskHistory}, a Side Navigation bar
 *
 * @see StartTask
 * @see TaskHistory
 */
const DashboardPage: React.FC = () => {
  const selectedLab = useLabStore(state => state.selectedLab);
  const { user } = useAuth();
  const { loading, data } = useQueryFetcher(getDashboard, {
    originFacilityId: selectedLab.facilityId,
  });

  return (
    <ModuleContainer>
      <ModuleHeader title={`Welcome back, ${user?.displayName}`} />
      <div className="p-6 grid xl:grid-cols-2 gap-4 overflow-auto h-full">
        <div className="flex flex-col gap-4">
          <StartTask />
          <TaskHistory orders={data?.userCases || []} loading={loading} />
        </div>
      </div>
    </ModuleContainer>
  );
};

export default DashboardPage;
