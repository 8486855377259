import { OrderProductAttribute, ReturnType, TechnicalPreference } from 'API';
import classNames from 'classnames';
import { ProcessSwapBadge } from 'components/common/ProcessSwapBadge';
import { ReturnTypeBadge } from 'components/common/ReturnTypeBadge';
import { excludedAttributes } from 'configurations/InvoiceConfiguration';
import { cloneDeep } from 'lodash';
import { CaseDetailModuleContext } from 'providers/CaseDetailModuleProvider';
import { useContext, useMemo } from 'react';
import { AttributeName, AttributeType, ErrorMessage } from 'shared/enums';
import { SpecialOrderParts } from 'shared/enums/special-order-parts';
import { getAttributeColor } from 'shared/helpers/attribute-color.helper';
import { hasValidSelectionsForMetalAlloys } from 'shared/helpers/invoice/invoice.helper';
import {
  getProductQuantity,
  getProductUnits,
  getToothValue,
  isLMSOrder,
} from 'shared/helpers/order-detail/order-detail.helper';
import { CreatedOrderItem, TabConfig } from 'shared/models';
import ConfigService from 'shared/services/config.service';
import { cn, convertToCurrency } from 'shared/utils';

/**
 * Props for the CaseOverviewProduct component.
 */
interface CaseOverviewProductProps {
  /**
   * The product details.
   */
  product: CreatedOrderItem;
  /**
   * The index of the product in the list.
   */
  index: number;
  /**
   * The total count of products in the list.
   */
  count: number;
  /**
   * Handler function for selecting a tab.
   */
  tabSelectHandler: (tab: TabConfig) => void;
}

const processAttributeFilter = (a: OrderProductAttribute) =>
  a.type === AttributeType.TechnicalPreferences && a.name === AttributeName.Process;

/**
 * Component to display an overview of a product in a case.
 * @param product - The product details.
 * @param index - The index of the product in the list.
 * @param count - The total count of products in the list.
 * @param tabSelectHandler - Handler function for selecting a tab.
 * @returns The JSX element representing the CaseOverviewProduct component.
 */
const CaseOverviewProduct: React.FC<CaseOverviewProductProps> = ({ product, index, count }) => {
  const { caseDetails, technicalPreferences } = useContext(CaseDetailModuleContext);
  const { subtotalAmount = 0, totalDiscountAmount = 0, productName, productCode } = product;
  const { originatingSystem } = caseDetails || {};
  const isLegacyCase = useMemo(() => !isLMSOrder(originatingSystem), [originatingSystem]);

  // Retrieves supported attribute types to display.
  const supportedAttributeTypes: string[] = JSON.parse(
    ConfigService.getConfigValue('Settings', 'CaseDetailsSupportedAttributeTypes') || '[]'
  );

  const productPreferences = technicalPreferences.filter(
    (technicalPreference: TechnicalPreference) =>
      technicalPreference?.productCode && technicalPreference?.productCode === productCode
  );

  // As per a conversation with Quenton, Josh, and the UX team, the product price should be the subtotal minus any discounts and should not include the tax.
  // The product subtotal should always exist (if there is an amount to pay). If not, please check with the service team.
  const getProductPrice = () => {
    return (subtotalAmount || 0) - (totalDiscountAmount || 0);
  };

  /**
   * Returns the quantity * unitPrice calculation for all order product attributes within a given array.
   * @param attributes - Order product attributes to add to the running total.
   * @returns the quantity * unitPrice calculation for all order product attributes within a given array.
   */
  const getOrderProductAttributePrice = (attributes: OrderProductAttribute[]) => {
    return attributes.reduce((runningTotal, { quantity, unitPrice }) => {
      // Defaulting the quantity to 0 after a conversation with Di and Josh, in case 0 is ever a valid quantity.
      const totalForAttribute = (quantity || 0) * (unitPrice || 0);
      return runningTotal + totalForAttribute;
    }, 0);
  };

  /**
   * Returns the total calculation for all addons and services for the target product.
   * @returns the total calculation for all addons and services for the target product.
   */
  const getAddonAndServicePrice = () => {
    const price = getOrderProductAttributePrice(product.addOns) + getOrderProductAttributePrice(product.services);
    return convertToCurrency(price);
  };

  const { productUnits, additionalToothUnits } = useMemo(() => getProductUnits(product), [product]);
  const productQuantity = useMemo(() => getProductQuantity(product), [product]);

  /**
   * When the user clicks on "View Complete History", navigates them to the Case History tab.
   */
  // const navigateToCaseHistory = () => {
  //   tabSelectHandler({
  //     title: CaseDetailTab.CaseHistory,
  //     current: false,
  //   });
  // };
  const toothValue = getToothValue(product).replace('#', '');
  const bodyShade = product.attributes.find(a => a.name === AttributeName.BodyShade);
  let isProcessSwap = false;

  // Maps the return type by finding the target product itemId in the existing order.
  let returnType: ReturnType | undefined | null;
  if (product.__typename === 'RmaOrderItem') {
    returnType = product.returnType;
    isProcessSwap = !!product.isProcessSwap;
  }

  //push tooth attribute to 0 index, As we need to render Tooth# always at the top
  const attributes = product.attributes;
  const toothIndex = product.attributes.findIndex(data => data.name === AttributeType.ToothString);
  if (toothIndex > -1) {
    const item = attributes[toothIndex];
    attributes.splice(toothIndex, 1);
    attributes.unshift(item);
  }

  //considering there will be only one metal alloy,
  const alloyAttribute = attributes.find(data => data.type === AttributeType.Alloy);
  const processAttribute = attributes.find(processAttributeFilter);
  const alloyNames = alloyAttribute?.name
    ? `${alloyAttribute.name ?? ''}${
        alloyAttribute.value && +alloyAttribute.value > 0 ? `, ${alloyAttribute.value} dwt` : ''
      }`
    : '';

  /**
   * Retrieves the color for the process attribute.
   */
  const processAttributeColor = useMemo(() => {
    if (!processAttribute) return '';
    return getAttributeColor({ ...processAttribute, productPreferences });
  }, [processAttribute, productPreferences]);

  /**
   * Formats attribute values based on the provided attribute taht need to be rendered as values for the respective attributes.
   *
   * @param attribute - The attribute object containing attribute details.
   * @returns string | JSX.Element The formatted attribute value.
   */
  const formatAttributeValues = (attribute: OrderProductAttribute) => {
    const addOns = cloneDeep(product.addOns);
    const attributeValue = attribute.value;
    const attributeName = attribute.name;
    let secondaryValueRed = false;
    let secondaryValue;
    let attributeValueString = attributeValue;

    if (attributeName === AttributeName.ToothString) {
      attributeValueString = toothValue;
    } else if (attributeName === AttributeName.AdditionalTooth) {
      attributeValueString = `Qty: ${attributeValue}`;
      if (attribute.unitPrice) {
        secondaryValue = convertToCurrency(attribute.unitPrice);
      }
    } else {
      if (attributeName === AttributeType.SpecialOrderParts) {
        const specialOrderPartsAmount = addOns.find(addOn => addOn.name === SpecialOrderParts.Amount);
        const specialOrderPartsNotes = addOns.find(addOn => addOn.name === SpecialOrderParts.Notes);

        // If notes exist in the order, replaces the Special Order Part add-on's value.
        if (specialOrderPartsNotes) {
          attributeValueString = specialOrderPartsNotes.value;
        }
        if (!specialOrderPartsAmount && attributeValueString === 'N/A') {
          secondaryValue = 'Missing implant part';
          secondaryValueRed = true;
        }
      }
      if (attribute.unitPrice) {
        secondaryValue = `${attribute.quantity ? `Qty: ${attribute.quantity}, ` : ''}+${convertToCurrency(
          attribute.unitPrice
        )}`;
      }
    }

    return (
      <span className="text-gray-400 flex">
        <span className="text-gray-500">{attributeValueString}</span>
        {secondaryValue && (
          <span className={classNames('ml-1', { 'text-red-600': secondaryValueRed })}>({secondaryValue})</span>
        )}
      </span>
    );
  };

  /**
   * Displays attributes for the target product.
   */
  const AttributeSection = () => {
    return (
      <div className="flex flex-col w-1/2">
        {attributes
          .filter(a => a.value && a.value.length && a.type !== AttributeType.Alloy)
          .filter(a => !processAttributeFilter(a))
          .filter(
            a =>
              (a.type === AttributeType.ReportGrouping && a.name === AttributeName.Department) ||
              supportedAttributeTypes.includes(a.type)
          )
          .filter(a => !excludedAttributes.includes(a.name.toLowerCase()))
          .map((attribute, attIndex) => {
            const { name: attributeName, type: attributeType } = attribute;

            // Determines what value to show for the attribute name.
            let attributeNameToShow = attributeName;
            if (attributeName === AttributeName.ToothString) {
              attributeNameToShow = 'Tooth #';
            } else if (
              attributeType === AttributeType.InclusiveImplantSystem ||
              attributeType === AttributeType.NonInclusiveImplantSystem
            ) {
              // Implant systems need to be displayed next to the implant name, as per LMS1-5548.
              attributeNameToShow = `${attributeName} (${attributeType})`;
            }

            const attributeColor = getAttributeColor({ ...attribute, productPreferences });
            return (
              <div key={`${attributeName}-${attIndex}`} className={classNames(attributeColor, 'flex text-xs mb-1')}>
                <div className="font-medium w-40">{attributeNameToShow}</div>
                <div data-qa={`${attributeName.replace(/\s/g, '')}Label`} className="ml-2">
                  {formatAttributeValues(attribute) || 'N/A'}
                </div>
              </div>
            );
          })}
        {alloyNames && alloyAttribute && (
          <div key="Metal Alloy" className={classNames('text-gray-500', 'flex text-xs mb-1')}>
            <div className="font-medium w-40">Metal Alloy</div>
            <div data-qa={`metal_alloy_label`} className="flex">
              {alloyNames}
              {!hasValidSelectionsForMetalAlloys([alloyAttribute]) && (
                <div className="text-red-500 flex-none ml-1">({ErrorMessage.MISSING_ALLOY_TYPE_OR_WEIGHT})</div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  /**
   * Displays additional information for the target product.
   */
  const AddOnsServicesAndProductionFacilitySection = () => {
    const addOns = cloneDeep(product.addOns);
    const specialOrderPartsInServices = product.services.find(a => a.name === AttributeType.SpecialOrderParts);
    if (specialOrderPartsInServices) {
      addOns.unshift({
        ...specialOrderPartsInServices,
        value: 'N/A',
      });
    }

    return (
      <div className="flex w-1/2 gap-4">
        <div>
          {addOns
            .filter(
              // Filters out add-ons without values.
              a => a.value && a.value.length && !Object.values(SpecialOrderParts).includes(a.name as SpecialOrderParts)
            )
            .map((attribute, attInd) => {
              const attributeName = attribute.name;
              const attributeColor = getAttributeColor({ ...attribute, productPreferences });
              return (
                <div key={`${attributeName}-${attInd}`} className={classNames(attributeColor, 'flex text-xs mb-1')}>
                  <div className="w-40 font-medium">{`(Add-on) ${attributeName}`}</div>
                  <div className="ml-2">{formatAttributeValues(attribute)}</div>
                </div>
              );
            })}
          {product.services
            .filter(
              // Filters out add-ons without values, as well as implant part add-ons (these will be handled with custom logic).
              a => a.value && a.value.length && a.name !== AttributeName.ImplantPart
            )
            .filter(a => !excludedAttributes.includes(a.name.toLowerCase()))
            .map((attribute, attInd) => {
              const attributeColor = getAttributeColor({ ...attribute, productPreferences });
              return (
                <div key={`${attribute.name}-${attInd}`} className={classNames(attributeColor, 'flex text-xs mb-1')}>
                  <div className="w-40 font-medium">{`(Service) ${attribute.name}`}</div>

                  <div className="ml-2">{formatAttributeValues(attribute)}</div>
                </div>
              );
            })}
          {(processAttribute?.value || caseDetails?.isCTScanEligible) && (
            <div className={cn('flex text-gray-500 text-xs mb-1', processAttributeColor)}>
              <div className="w-40 font-medium">{AttributeName.Process}</div>
              <div data-qa="processLabel" data-testid="processLabel" className="ml-2">
                {processAttribute?.value || 'CT Scan - Yes'}
              </div>
            </div>
          )}
          <div className="flex text-gray-500 text-xs mb-1">
            <div className="w-40 font-medium">Production Facility</div>

            <div className="text-gray-500 ml-2" data-qa="productionFacilityLabel" data-testid="productionFacilityLabel">
              {product.manufacturingLocation || 'N/A'}
            </div>
          </div>
          <div className="flex text-gray-500 text-xs mb-1">
            <div className="w-40 font-medium">Department</div>

            <div className="text-gray-500 ml-2" data-qa="departmentLabel" data-testid="departmentLabel">
              {product.department || 'N/A'}
            </div>
          </div>
          <div className="flex text-gray-500 text-xs mb-1">
            <div className="w-40 font-medium">Days in Lab</div>

            <div className="text-gray-500 ml-2" data-qa="daysInLabLabel" data-testid="daysInLabLabel">
              {product.daysInLab || 'N/A'}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex gap-4 border border-gray-200 bg-white p-4 rounded-t-lg">
        <div className="flex flex-col gap-1">
          <div className="text-gray-500 text-sm font-medium" data-testid="productCountLabel">{`Product ${
            index + 1
          } of ${count}`}</div>
          <div data-qa="productNameLabel" data-testid="productNameLabel">
            {productName}
            {isLegacyCase ? ` (${productCode})` : ''}
          </div>
        </div>
        {toothValue && (
          <div className="flex flex-col gap-1">
            <div className="text-gray-500 text-sm font-medium">Tooth #</div>
            <div className="text-center" data-qa="toothNumberLabel" data-testid="toothNumberLabel">
              {toothValue}
            </div>
          </div>
        )}
        {!!bodyShade && (
          <div className="flex flex-col gap-1">
            <div className="text-gray-500 text-sm font-medium">Body Shade</div>
            <div className="text-center" data-qa="bodyShadeLabel" data-testid="bodyShadeLabel">
              {bodyShade?.value ?? '--'}
            </div>
          </div>
        )}
        <div className="flex flex-col gap-1">
          <div className="text-gray-500 text-sm font-medium">Units</div>
          <div className="text-center" data-qa="productUnitLabel" data-testid="productUnitLabel">
            {/* In LMS1-8081, we established that product units default to 1 */}
            {productUnits || 1}
            {!!additionalToothUnits && ` (+${additionalToothUnits})`}
          </div>
        </div>
        {returnType && (
          <div className="flex flex-col gap-1">
            <div className="text-gray-500 text-sm font-medium">Return Type</div>
            <div className="text-center flex" data-qa="returnTypeLabel" data-testid="returnTypeLabel">
              <ReturnTypeBadge type={returnType} />
              {isProcessSwap && (
                <div className="ml-2">
                  <ProcessSwapBadge />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="border-l border-r p-4 border-gray-200 bg-white flex gap-1">
        <AttributeSection />
        <AddOnsServicesAndProductionFacilitySection />
      </div>
      <div className="border-l border-r p-4 text-gray-500 text-xs flex flex-row-reverse bg-white">
        Override
        <p className="ml-4 mr-1 inline-block h-3 w-3 border bg-orange-500 rounded-full mt-0.5 p-px"></p>
        Preference
        <p className="ml-4 mr-1 inline-block h-3 w-3 border bg-teal-500 rounded-full mt-0.5"></p>
        {/* TODO: Showing this asterisk would require making an extra query for product information so Josh, Quenton and I agreed to comment it out for now. */}
        {/* <p className="inline mb-1">* Lab default if not specified by doctor</p> */}
      </div>
      <div className="border rounded-b-lg p-4 flex bg-white gap-6 mb-2">
        <div className="flex gap-2 flex-grow">
          <div className="text-gray-500 text-sm font-medium my-0.5 flex-grow text-right">Qty</div>
          <div className="text-gray-900 text-base" data-qa="productQuantityLabel" data-testid="productQuantityLabel">
            {productQuantity}
          </div>
        </div>
        <div className="flex gap-2">
          <div className="text-gray-500 text-sm font-medium my-0.5">Qty Price</div>
          <div className="text-gray-900 text-base" data-qa="productUnitPriceLabel" data-testid="productUnitPriceLabel">
            {convertToCurrency(product.unitPrice ?? 0)}
          </div>
        </div>
        <div className="flex gap-2">
          <div className="text-gray-500 text-sm font-medium my-0.5">Add Ons/Services</div>
          <div
            className="text-gray-900 text-base"
            data-qa="addonServicePriceLabel"
            data-testid="addonServicePriceLabel"
          >
            {getAddonAndServicePrice()}
          </div>
        </div>
        <div className="flex gap-2">
          <div className="text-gray-500 text-sm font-medium my-0.5">Discounts</div>
          <div className="text-gray-900 text-base" data-qa="productDiscountLabel" data-testid="productDiscountLabel">
            {convertToCurrency(totalDiscountAmount)}
          </div>
        </div>
        <div className="flex gap-2">
          <div className="text-gray-500 text-sm font-medium my-0.5">Price</div>
          <div className="text-gray-900 text-base" data-qa="productPriceLabel" data-testid="productPriceLabel">
            {convertToCurrency(getProductPrice())}
          </div>
        </div>
      </div>
    </>
  );
};

export default CaseOverviewProduct;
