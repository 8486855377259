import { ShippingPreferences } from 'API';
import { snakeCase } from 'lodash';
import { InvoicingDetailContext, useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { useManufacturingLocation } from 'providers/ManufacturingLocationProvider';
import { useCallback, useEffect, useMemo } from 'react';
import { shippingPreferences } from 'shared/api/invoice.api';
import { PACIFIC_TIMEZONE_CODE, getDoNotShipMessage } from 'shared/constants/invoice.constants';
import { DO_NOT_SHIP_PREFERENCE_ENUM, ShippingCarrier } from 'shared/enums';
import { getLocaleTimeZoneDate } from 'shared/helpers/date.helper';
import { getIsPastCutOff, getLabCutOffUnixTime } from 'shared/helpers/invoice/invoice.helper';
import { useQueryFetcher } from 'shared/hooks/useQueryFetcher';
import { QueryFetchTemplate } from 'templates/QueryFetchTemplate/QueryFetchTemplate';
import { DoNotShipElement } from './DoNotShipElement/DoNotShipElement';
import { ShippingPreferenceGreenBox } from './ShippingPreferenceGreenBox/ShippingPreferenceGreenBox';

/**
 * Props for the ContentLoaded component.
 */
interface ContentLoadedProps {
  /**
   * Data containing shipping preferences.
   */
  data: ShippingPreferences;
}

/**
 * Component to display content when shipping preferences data is loaded.
 * @param data - Data containing shipping preferences.
 * @returns The JSX element representing the ContentLoaded component.
 */
const ContentLoaded: React.FC<ContentLoadedProps> = ({ data }) => {
  const { setInvoiceShippingInput, invoiceShippingInput, shippingOptions, outboundApplicableCase } =
    useInvoicingDetail();
  const { carrier, doNotShip, isSignatureRequiredPreference, doNotShipPreference } = invoiceShippingInput;
  const utcConversionTimeZoneCode = outboundApplicableCase?.utcConversionTimeZoneCode ?? PACIFIC_TIMEZONE_CODE;

  /**
   * Update shipping input with preference values.
   * If there is a tracking number entered, ignore the logic here so we don't overwrite the outbound options
   * from the tracking number case.
   */
  useEffect(() => {
    if (invoiceShippingInput.trackingNumberBundledOrder) return;

    const {
      carrier: preferredCarrier,
      service: preferredService,
      options: preferredOptions,
    } = data.preferredCarrier || {};
    /**
     * Saves the preference values to a new object storing the invoice shipping input
     * If the preferences match a shipping option, we will set the default values to their preferences below.
     */
    const carrierPreference = preferredCarrier;
    const servicePreference = preferredService ? snakeCase(preferredService).toUpperCase() : undefined;
    const isSignatureRequiredPreference = preferredOptions?.signatureRequired;
    const newInvoiceShippingInput: Partial<InvoicingDetailContext['invoiceShippingInput']> = {
      doNotShip: data?.doNotShip,
      isSignatureRequired: isSignatureRequiredPreference || false,
      isSignatureRequiredPreference: isSignatureRequiredPreference ?? null,
      carrierPreference,
      servicePreference,
    };
    /**
     * Cycles through the available shipping options to make sure the carrier and service preferences have a match.
     * Stores the exact carrier name and service name to ensure the selected carrier and service match the preference.
     */
    let matchingCarrierOption = '';
    let matchingServiceOption = '';
    shippingOptions.forEach(shippingOption => {
      if (carrierPreference && shippingOption.carrier.toUpperCase() === carrierPreference.toUpperCase()) {
        matchingCarrierOption = shippingOption.carrier;
        if (servicePreference && shippingOption.service.toUpperCase() === servicePreference.toUpperCase()) {
          matchingServiceOption = shippingOption.service;
        }
      }
    });
    // If the preferred carrier name matches an available carrier, uses the carrier's name from the match as both the preference and the value to ensure they are always equal.
    if (matchingCarrierOption) {
      newInvoiceShippingInput.carrier = matchingCarrierOption;
      newInvoiceShippingInput.carrierPreference = matchingCarrierOption;
    }
    // If the preferred service name matches an available service for that carrier, uses the service name from the match as both the preference and the value to ensure they are always equal.
    if (matchingServiceOption) {
      newInvoiceShippingInput.shippingService = matchingServiceOption;
      newInvoiceShippingInput.servicePreference = matchingServiceOption;
    }

    // As per LMS1-7393, local pickup should be a valid shipping preference.
    // DoctorPickup is the valid shipping option expected by the service.
    const isDoctorPickup =
      carrierPreference === ShippingCarrier.LocalPickup || carrierPreference === ShippingCarrier.DoctorPickup;
    if (isDoctorPickup) {
      newInvoiceShippingInput.carrier = ShippingCarrier.DoctorPickup;
      newInvoiceShippingInput.carrierPreference = ShippingCarrier.DoctorPickup;
    }

    setInvoiceShippingInput(newInvoiceShippingInput);
  }, [data, invoiceShippingInput.trackingNumberBundledOrder, setInvoiceShippingInput, shippingOptions]);

  /**
   * Checks to see if a shipping preference exists for this account. If it does, returns a text description to display.
   * Returns a description for the shipping preference, if one exists.
   */
  const shippingPreferenceDescription = useMemo(() => {
    let descriptionToReturn = '';
    // The way the shipping preferences are currently set up, if a carrier exists, there is a preference.
    if (carrier && isSignatureRequiredPreference) {
      descriptionToReturn = 'Always Require Signature';
    } else if (isSignatureRequiredPreference !== null && carrier && !isSignatureRequiredPreference) {
      descriptionToReturn = 'Signature Not Required';
    }

    return descriptionToReturn;
  }, [carrier, isSignatureRequiredPreference]);

  /**
   * Returns true if there is a do not ship for the target day of week.
   * @param dayOfWeek - The day of week to return a do not ship result for.
   * @returns true if there is a do not ship for the target day of week.
   */
  const isDoNotShipForThisDayOfWeek = useCallback(
    (dayOfWeek: string) => {
      return doNotShip.some(dns => dns.day === dayOfWeek);
    },
    [doNotShip]
  );

  const hasDoNotShip = doNotShip && doNotShip.length > 0;

  const currentDay = getLocaleTimeZoneDate(new Date(), utcConversionTimeZoneCode).format('dddd');
  const currentDate = getLocaleTimeZoneDate(new Date(), utcConversionTimeZoneCode);
  const nextDay = getLocaleTimeZoneDate(new Date(), utcConversionTimeZoneCode).add(1, 'days').format('dddd');
  const nextDate = getLocaleTimeZoneDate(new Date(), utcConversionTimeZoneCode).add(1, 'days');
  const { getManufacturingLocation } = useManufacturingLocation();
  const manufacturingLocation = getManufacturingLocation(outboundApplicableCase?.originFacilityId || 0);
  const labCutoffTime = getLabCutOffUnixTime(manufacturingLocation);
  const isPastCutoff = getIsPastCutOff(labCutoffTime);

  /**
   * Filters preferences that match the current day if not past the lab cutoff time or the next day if past the lab cutoff time.
   * Also, filters for preferences that don't have a specific day but have a start and end with the date to check within the start and end.
   */
  const currentDateTimeWithinDnsLabCutoff = useMemo(() => {
    let dayToUse = isPastCutoff ? nextDay : currentDay;
    let dateToUse = isPastCutoff ? nextDate : currentDate;

    // As per LMS1-5256, if it is Saturday or Sunday, and there isn't a do not ship for today, but there is for yesterday, show the do not ship for yesterday instead.
    const isSaturdayOrSunday = currentDay === 'Saturday' || currentDay === 'Sunday';
    const isDoNotShipToday = isDoNotShipForThisDayOfWeek(currentDay);
    const yesterdayDate = getLocaleTimeZoneDate(new Date(), utcConversionTimeZoneCode).subtract(1, 'days');
    const yesterdayDayOfWeek = yesterdayDate.format('dddd');
    const isUseDoNotShipForYesterday =
      isSaturdayOrSunday && !isDoNotShipToday && isDoNotShipForThisDayOfWeek(yesterdayDayOfWeek);

    if (isUseDoNotShipForYesterday) {
      dayToUse = yesterdayDayOfWeek;
      dateToUse = yesterdayDate;
    }
    // Since we do not ship on Sundays, skips to Monday as per LMS1-5256.
    else if (dayToUse === 'Sunday') {
      dayToUse = 'Monday';
      dateToUse = getLocaleTimeZoneDate(dateToUse.toDate(), utcConversionTimeZoneCode).add(1, 'days');
    }

    return doNotShip
      .filter(dns => dns.day === dayToUse || (!dns.day && dns.end))
      .find(dns => {
        return (
          getLocaleTimeZoneDate(dns.start, utcConversionTimeZoneCode) <= dateToUse &&
          (!dns.end || getLocaleTimeZoneDate(dns.end, utcConversionTimeZoneCode) >= dateToUse)
        );
      });
  }, [
    isPastCutoff,
    nextDay,
    currentDay,
    nextDate,
    currentDate,
    doNotShip,
    isDoNotShipForThisDayOfWeek,
    utcConversionTimeZoneCode,
  ]);

  const doNotShipMessage = currentDateTimeWithinDnsLabCutoff && getDoNotShipMessage(currentDateTimeWithinDnsLabCutoff);

  const descriptions = useMemo(() => {
    const array = [];
    if (shippingPreferenceDescription) {
      array.push(shippingPreferenceDescription);
    }

    if (doNotShipPreference !== DO_NOT_SHIP_PREFERENCE_ENUM.NONE && doNotShipMessage) {
      array.push(doNotShipMessage);
    }
    return array;
  }, [doNotShipPreference, doNotShipMessage, shippingPreferenceDescription]);

  return (
    <div className="mt-3 flex flex-col gap-2">
      <ShippingPreferenceGreenBox descriptions={descriptions} />
      {hasDoNotShip && (
        <DoNotShipElement
          doNotShipMessage={doNotShipMessage}
          currentDateTimeWithinDns={currentDateTimeWithinDnsLabCutoff}
        />
      )}
    </div>
  );
};

/**
 * Component to fetch and display shipping preferences.
 * @returns The JSX element representing the ShippingPreferenceElement component.
 */
export const ShippingPreferenceElement = () => {
  const { account } = useInvoicingDetail();
  const { loading, data } = useQueryFetcher(shippingPreferences, {
    billingAccountId: account?.billingAccountId || '',
    skip: !account?.billingAccountId,
  });

  return (
    <QueryFetchTemplate loading={loading} data={data}>
      {({ data }) => data && <ContentLoaded data={data} />}
    </QueryFetchTemplate>
  );
};
