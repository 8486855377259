import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createLabSlice, LabStore } from './createLabStoreSlice';

export const useLabStore = create<LabStore>()(
  devtools(
    (...args) => ({
      ...createLabSlice(...args),
    }),
    { serialize: true }
  )
);
