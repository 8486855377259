import { ILab, LABS } from 'shared/constants/labs.constants';
import { StateCreator } from 'zustand';

export interface State {
  selectedLab: ILab;
}

export interface Actions {
  setSelectedLab: (lab: ILab) => void;
}

export type LabStore = State & Actions;

export const createLabSlice: StateCreator<LabStore> = set => ({
  selectedLab: LABS[0],
  setSelectedLab: lab => {
    return set(() => ({
      selectedLab: lab,
    }));
  },
});
