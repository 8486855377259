import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { CurrencyCode, EnclosedItemCategory, ShippingChargeType, ShippingOption } from 'API';
import Input from 'components/common/Input/Input';
import InputError from 'components/common/InputError';
import Label from 'components/common/Label';
import Loader from 'components/common/Loader/Loader';
import RemoveButton from 'components/common/RemoveButton';
import { InvoicingDetailState, useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { useManufacturingLocation } from 'providers/ManufacturingLocationProvider';
import { useEffect, useRef, useState } from 'react';
import { validateExistingTrackingNumber } from 'shared/api/order.api';
import {
  isCaseLockedForInvoicing,
  someCasesHaveMetalArticulator,
  someEnclosedItemsHaveMetalArticulator,
} from 'shared/helpers/invoice/invoice.helper';
import { getCarrierName } from 'shared/helpers/order-entry/order-entry.helper';
import { getSelectedShippingCharge } from 'shared/helpers/util.helper';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import {
  getOutboundApplicableCase,
  getProductReturnRequirements,
  getShippingAddress,
  isPendingOrderTypename,
  isRmaOrderTypename,
} from 'shared/utils';
import { OrderTransactionSearchNonPendingOrder, OrderTransactionSearchRmaOrder } from 'types/order-transaction';

/**
 * Props for the TrackingNumberInputValidation component.
 */
interface TrackingNumberInputValidationProps {
  /**
   * Boolean indicating whether the tracking number is valid or not.
   */
  isValid: boolean;
  /**
   * Optional error message to display when the tracking number is invalid.
   */
  errorMessage?: string;
}

const ARTICULATOR_ERROR = "Cases with metal articulators can't be bundled.";
const INVALID_ERROR = 'Invalid tracking number';
const SERVER_ERROR = 'Failed to get results properly. Please try again.';
const MISSING_TRACKING_ERROR = 'Please enter a tracking number';

/**
 * Component to display validation message for tracking number input.
 * @param isValid - Boolean indicating whether the tracking number is valid or not.
 * @param errorMessage - Optional error message to display when the tracking number is invalid.
 * @returns JSX element representing the validation message.
 */
const TrackingNumberInputValidation: React.FC<TrackingNumberInputValidationProps> = ({ isValid, errorMessage }) => {
  return (
    <>
      {errorMessage ? (
        <InputError message={errorMessage} />
      ) : (
        isValid && <div className="text-sm mt-1 text-green-500">Valid tracking number</div>
      )}
    </>
  );
};

/**
 * Props for the TrackingNumberInput component.
 */
interface TrackingNumberInputProps {
  /**
   * Function to handle hiding the tracking number input.
   */
  onHideTrackingNumber: () => void;
}

/**
 * Component for entering tracking number and validating it.
 * @param onHideTrackingNumber - Function to handle hiding the tracking number input.
 * @returns JSX element representing the tracking number input.
 */
export const TrackingNumberInput: React.FC<TrackingNumberInputProps> = ({ onHideTrackingNumber }) => {
  const {
    invoiceShippingInput,
    setInvoiceShippingInput,
    setShippingOptions,
    account,
    shipping: invoiceShipping,
    invoiceCases,
  } = useInvoicingDetail();
  const { loading, fetcher } = useLazyQueryFetcher(validateExistingTrackingNumber);
  const [errorMessage, setErrorMessage] = useState('');
  const trackingNumberRef = useRef<HTMLInputElement>(null);
  const { getManufacturingLocation } = useManufacturingLocation();
  const { trackingNumberBundledOrder, trackingNumber } = invoiceShippingInput;
  const isValidTrackingNumber =
    !!trackingNumberBundledOrder && !!trackingNumberBundledOrder.orderNumber && !errorMessage;

  /**
   * Validates the tracking number and sets error message if invalid.
   * @returns boolean - Indicates whether the tracking number is valid.
   */
  const validateTrackingNumber = () => {
    const carrierName = getCarrierName(trackingNumber);
    if (!trackingNumber) {
      setErrorMessage(MISSING_TRACKING_ERROR);
    } else if (!carrierName) {
      setErrorMessage(INVALID_ERROR);
    } else {
      setErrorMessage('');
    }
    return !!(trackingNumber && carrierName);
  };

  /**
   * Handles form submission and validates the tracking number.
   */
  const onSubmit = async () => {
    if (errorMessage) return;
    if (!trackingNumber) return setErrorMessage(MISSING_TRACKING_ERROR);
    if (!validateTrackingNumber()) return;

    setErrorMessage('');
    try {
      // Fetch order data based on the tracking number
      const fetchResult = await fetcher({ trackingNumber });

      // Extract RMA order items from the fetch result
      const rmaOrderItems = fetchResult
        .filter((item): item is OrderTransactionSearchRmaOrder => isRmaOrderTypename(item))
        .flatMap(item => item.orderItems);

      // Filter non-pending orders from the fetch result
      const nonPendingOrders = fetchResult.filter((item): item is OrderTransactionSearchNonPendingOrder => {
        return !isPendingOrderTypename(item);
      });

      // Get product return requirements based on RMA order items
      const productReturnRequirements = await getProductReturnRequirements(rmaOrderItems);

      // Get the case eligible for charge
      const chargeEligibleCase = getOutboundApplicableCase(nonPendingOrders, productReturnRequirements);

      /**
       * If there are no charge eligible cases then we need to grab shipping charges from the case that has them set
       * to ensure that a charge is set on the current case if it is eligible.
       * Requirement: LMS1-6143
       * Update (09/24/2024) - We identified the need to find the first order transaction that includes an outbound charge.
       * This is because an outbound charge might not be available until a later transaction.
       * Without an outbound charge being available in the transaction being targeted,
       * no shipping options will be available to select and the order cannot be invoiced.
       */
      const result = fetchResult.find((item): item is OrderTransactionSearchNonPendingOrder => {
        return (
          !isPendingOrderTypename(item) &&
          !!item.shipping?.shippingCharges?.find(charge => charge?.type === ShippingChargeType.Outbound)
        );
      });

      if (!result) throw Error('No case found.');

      // Validate billing account ID
      const { billingAccountId, shipping, enclosedItems } = result;
      if (billingAccountId !== account?.billingAccountId) {
        return setErrorMessage('Account # doesn’t match the first invoiced case.');
      }

      // Get manufacturing location and lab cutoff time
      const manufacturingLocation = getManufacturingLocation(result?.originFacilityId ?? 0);

      // Restrict bundling if the case is locked for invoicing
      if (isCaseLockedForInvoicing(result, manufacturingLocation)) {
        return setErrorMessage(`Case locked for invoicing.`);
      }

      // Validate shipping address
      if (getShippingAddress(shipping?.address) !== getShippingAddress(invoiceShipping?.address)) {
        return setErrorMessage(`Shipping address doesn’t match the first invoiced case.`);
      }

      // If the case has any outbound articulators, don't allow bundling.
      if (
        someEnclosedItemsHaveMetalArticulator(
          enclosedItems.filter(item => item.shippingType === EnclosedItemCategory.Outbound)
        )
      ) {
        setInvoiceShippingInput({ trackingNumberBundledOrder: undefined, inValidTrackingNumber: true });
        return setErrorMessage(ARTICULATOR_ERROR);
      }

      // Get selected shipping charge
      const selectedShippingCharge = getSelectedShippingCharge(shipping?.shippingCharges, shipping?.packageType);

      // Update invoice shipping input with new order data
      const updatedInvoiceShippingInput: Partial<InvoicingDetailState['invoiceShippingInput']> = {
        trackingNumberBundledOrder: {
          orderNumber: result.orderNumber,
          shipmentId: shipping?.shipmentId || '',
          rateId: shipping?.rateId || '',
          shippingCharges: shipping?.shippingCharges || [],
          isChargeEligible: !!chargeEligibleCase,
        },
        ...selectedShippingCharge,
        inValidTrackingNumber: false,
      };
      setInvoiceShippingInput(updatedInvoiceShippingInput);

      // Create shipping option based on the added order data
      const {
        carrier,
        totalAmount,
        isSaturdayDelivery,
        packageType,
        trackingNumberBundledOrder,
        isSignatureRequired,
        shippingService,
      } = updatedInvoiceShippingInput;
      const trackingNumberShippingOption: ShippingOption = {
        __typename: 'ShippingOption',
        carrier: carrier || '',
        charges: trackingNumberBundledOrder?.shippingCharges.map(charge => ({
          __typename: 'Charge',
          currencies: [
            {
              __typename: 'Currency',
              currencyCode: account?.currencyCode || CurrencyCode.Usd,
              price: charge.amount,
            },
          ],
          isTaxable: false,
          service: charge.service,
          shippingChargeId: charge.id,
        })),
        cost: totalAmount || 0,
        isSaturdayDelivery: isSaturdayDelivery || false,
        package: packageType,
        rateId: trackingNumberBundledOrder?.rateId || '',
        requiresSignature: isSignatureRequired || false,
        service: shippingService || '',
        shipmentId: trackingNumberBundledOrder?.shipmentId || '',
      };
      setShippingOptions([trackingNumberShippingOption]);
    } catch (err) {
      const error = err as Error;
      if (error.name === 'NotFoundError') {
        setErrorMessage(`Data not found for tracking number: ${trackingNumber}`);
      } else {
        setErrorMessage(SERVER_ERROR);
      }
    }
  };

  /**
   * Handles form submission event.
   * @param e - Form submission event.
   */
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  useEffect(() => {
    /**
     * Below condition will check if any of the cases have metal articulators from the outbound enclosed items
     */
    const isCasesHavingArticulates = someCasesHaveMetalArticulator(invoiceCases);
    const errorMessageToUse = isCasesHavingArticulates ? ARTICULATOR_ERROR : '';
    setErrorMessage(errorMessageToUse);

    // Resets trackingNumberBundledOrder and show error if there is a non-articulator error message.
    if (errorMessageToUse && errorMessageToUse !== ARTICULATOR_ERROR) {
      setInvoiceShippingInput({ trackingNumberBundledOrder: undefined, inValidTrackingNumber: true });
    }
  }, [setErrorMessage, setInvoiceShippingInput, invoiceCases]);

  /**
   * Handles change in input value.
   * @param e - Input change event.
   */
  const onChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInvoiceShippingInput({ trackingNumber: value });
    setErrorMessage('');
  };

  /**
   * Handles remove button press event.
   */
  const onRemovePress = () => {
    setErrorMessage('');
    onHideTrackingNumber();
  };

  return (
    <div className="mt-3 mb-1">
      <Label required htmlFor="trackingNumber">
        Existing Tracking Number
      </Label>
      <div className="max-w-xs flex">
        <form className="flex-grow" onSubmit={handleSubmit}>
          <Input
            ref={trackingNumberRef}
            name="trackingNumber"
            id="trackingNumber"
            placeholder="Enter tracking #"
            data-qa="trackingNumberInput"
            value={trackingNumber}
            isInvalid={!!errorMessage}
            autoFocus
            onChange={onChangeHandle}
            disabled={loading}
            onBlur={onSubmit}
            icon={
              isValidTrackingNumber && !loading ? (
                <CheckBadgeIcon className="h-5 text-green-500" />
              ) : (
                <Loader show={loading} className="text-white" spin />
              )
            }
            showIcon={loading || isValidTrackingNumber}
          />
          <input type="submit" hidden />
        </form>
        <RemoveButton id="trackingNumber" onRemove={onRemovePress} />
      </div>
      <TrackingNumberInputValidation isValid={isValidTrackingNumber} errorMessage={errorMessage} />
    </div>
  );
};
