import {
  Charge,
  DeliveryMethod,
  EnclosedItemCategory,
  InvoiceInput,
  InvoiceShipping,
  InvoiceShippingCharge,
  OrderStatus,
  OverrideFieldInput,
  OverrideFieldType,
  PackageType,
  PhoneType,
  ShippingChargeType,
  ShippingOption,
} from 'API';
import ModuleContainer from 'components/common/ModuleContainer';
import ModuleHeader from 'components/common/ModuleHeader';
import InvoiceDetail from 'components/invoicing/InvoiceDetail/InvoiceDetail';
import InvoiceHeader from 'components/invoicing/InvoiceHeader/InvoiceHeader';
import RightPanel from 'components/invoicing/RightPanel';
import { ROUTES } from 'components/navigation/Constants';
import { usePrevious } from 'hooks/use-previous';
import { sumBy } from 'lodash';
import { useAlertModal } from 'providers/AlertModalProvider';
import { useAuth } from 'providers/AuthProvider';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { useManufacturingLocation } from 'providers/ManufacturingLocationProvider';
import { OverlayLoaderContext } from 'providers/OverlayLoaderProvider';
import { ToastContext } from 'providers/ToastProvider';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getShippingOptions, invoice, invoiceAccountLookup } from 'shared/api/invoice.api';
import { getOrderFull } from 'shared/api/order.api';
import { CancelActivity } from 'shared/constants/case-detail.constants';
import { INVALID_INVOICE_STATUSES } from 'shared/constants/invoice.constants';
import { PERMISSION_DENIED, PERMISSION_DENIED_TEXT } from 'shared/constants/role-based-access-control';
import { ErrorMessage, ShippingCarrier, ShippingService, ToastNotificationType } from 'shared/enums';
import { AnalyticsEventName } from 'shared/enums/analytics';
import { ACTIONS } from 'shared/enums/permission';
import {
  filterEnclosedItemsByShippingType,
  getDefaultShipping,
  getDoctorPickupCarrierItem,
  someCasesHaveMetalArticulator,
  validateMetalAlloyAttributes,
  validateOrderItemsSpecialOrderParts,
} from 'shared/helpers/invoice/invoice.helper';
import { getOutboundDiscountAmount } from 'shared/helpers/order-detail/order-detail.helper';
import { concatOrderNumber } from 'shared/helpers/order-entry/order-entry.helper';
import { checkPriceOrTaxBeingCalculated, checkTotalDiscountAmount, roundDecimal } from 'shared/helpers/util.helper';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { useRoleBasedAccessControl } from 'shared/hooks/useRoleBasedAccessControl';
import { CreatedOrder, LocalOrderEnclosedItem } from 'shared/models';
import { InvoiceSummaryRouteState } from 'shared/models/invoicing-shipping';
import { AnalyticsService } from 'shared/services/analytics.service';
import {
  checkCaseInvalidInvoiceStatus,
  getCaseStatusRecord,
  getOrderCodAmount,
  getOutboundApplicableIndex,
  getPhoneToUse,
  getProductReturnRequirements,
  getShippingOptionsRequestBody,
  isAccountOnCod,
  isCreatedOrder,
} from 'shared/utils';
import { FetchShippingOptionsInput } from 'types/common';

/**
 * Renders a page presenting
 *  Case Information
 *  Shipment Selection
 *  Discount applied
 *  and an Invoice Summary
 */
const InvoicingPage: React.FC = () => {
  const { id: orderNumber } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const overlayLoader = useContext(OverlayLoaderContext);
  const toast = useContext(ToastContext);
  const {
    loading: orderLoading,
    fetcher: getOrderFetcher,
    initialized: invoiceInitialized,
  } = useLazyQueryFetcher(getOrderFull);
  const { loading: accountLoading, fetcher: invoiceAccountLookupFetcher } = useLazyQueryFetcher(invoiceAccountLookup);
  const [shippingLoading, setShippingLoading] = useState(true);
  const { fetcher: getShippingOptionsFetcher } = useLazyQueryFetcher(getShippingOptions);
  const { fetcher: invoiceFetcher } = useLazyQueryFetcher(invoice);
  const [uTCConversionTimeZone, setUTCConversionTimeZone] = useState('');
  const [invoiceError, setInvoiceError] = useState<Error | null>(null);
  const {
    setAccount,
    setShipping,
    setCases,
    setInvoiceShippingInput,
    setOnSubmitAlertCount,
    invoiceShippingInput,
    invoiceCases,
    onSubmitAlertCount,
    account,
    getSelectedShippingData,
    setShippingOptions,
    spiltBundleCaseInfo,
    newCase,
    doNotShipOnSaturday,
    setProductReturnRequirements,
    codAmount,
    setCodAmount,
  } = useInvoicingDetail();
  const { getManufacturingLocation } = useManufacturingLocation();
  const { user } = useAuth();
  const currentUserName = user?.displayName || '';
  const alert = useAlertModal();
  const prevInvoiceCase = usePrevious(invoiceCases);
  const invoiceCasePermission = useRoleBasedAccessControl(ACTIONS.INVOICE_CASE);
  const prevOrderNumber = usePrevious(orderNumber);

  /**
   * !TODO: This is not a good way to handle this. We should be passing this data through the route state.
   * Actually, this cause a problem when the user directly navigates to this page.
   * We have to change this to a better way in future.
   */
  const ordersScanned = useMemo(() => location?.state?.ordersScanned || [], [location?.state?.ordersScanned]);

  /**
   * returns a list of alls RMA order items in all orders
   */
  const getAllInvoiceRmaOrderItems = useCallback(
    (orders: CreatedOrder[]) =>
      orders.flatMap(order => {
        return order.__typename === 'RmaOrder' ? order.orderItems : [];
      }),
    []
  );

  const {
    fetcher: getProductReturnRequirementsFetcher,
    data: productReturnRequirements,
    loading: isInvoiceSummaryLoading,
  } = useLazyQueryFetcher(getProductReturnRequirements);
  /**
   * Handles refresh of product return requirements.
   */
  const refreshProductReturnRequirements = useCallback(
    async (createdOrders: CreatedOrder[]) => {
      const allInvoiceRmaOrderItems = getAllInvoiceRmaOrderItems(createdOrders);
      const response = await getProductReturnRequirementsFetcher(allInvoiceRmaOrderItems);
      setProductReturnRequirements(response);
    },
    [getAllInvoiceRmaOrderItems, getProductReturnRequirementsFetcher, setProductReturnRequirements]
  );

  /**
   * shows a warning alert dialog, if the user is not authorized to invoice an order
   */
  useEffect(() => {
    if (!invoiceCasePermission.allowCreate) {
      alert.show(PERMISSION_DENIED, PERMISSION_DENIED_TEXT);
      navigate(-1);
    }
  }, [alert, invoiceCasePermission, navigate]);

  /**
   * Fetches the ShippingOptions by using the fetcher function
   * @param invoiceCase - The main LMS case for which the shipping will be created
   * @param account - The account which requests the invoice
   * @param subtotalAmount - Cost without shipping
   * @param codAmount - COD amount if any
   * @returns
   */
  const fetchShippingOptions = useCallback(
    async ({ invoiceCase, account, subtotalAmount, initialCodAmount }: FetchShippingOptionsInput) => {
      try {
        const requestBody = getShippingOptionsRequestBody(
          invoiceCase,
          invoiceCases,
          account,
          subtotalAmount,
          initialCodAmount
        );
        const result = await getShippingOptionsFetcher(requestBody);
        const location = getManufacturingLocation(invoiceCase.originFacilityId || 0);
        // generate doctor pickup carrier item for each package type
        const doctorPickupShippingOptions = Object.values(PackageType).map(packageType => {
          return getDoctorPickupCarrierItem(packageType, location?.locationName || 'Not Found');
        });
        // TODO: Update AppSync ShippingCharge.charges array to not be nullable. Per Carlos, value will always exist.
        // Don't show options that do not have a charge matching the service.
        const filterFn = (option: ShippingOption) => {
          return option.charges && option.charges.some(charge => charge.service === option.service);
        };
        const shippingOptions = result.shippingOptions.filter(filterFn);
        return [...shippingOptions, ...doctorPickupShippingOptions];
      } catch (err) {
        const error = err as Error;
        const errorMessage = error.message || 'Failed to fetch shipping options';
        throw new Error(errorMessage);
      } finally {
        setShippingLoading(false);
      }
    },
    [getManufacturingLocation, getShippingOptionsFetcher, invoiceCases]
  );

  /**
   * fetched the order basing on orderNumber provided,
   * performs various checks and throws error accordingly if checks failed
   *
   * @param orderNumber - represents the order number of order which needs to be fetched
   * @returns {@link LmsOrder} if order is valid or null
   */
  const onFetchOrder = useCallback(
    async (orderNumber: string | undefined) => {
      if (!orderNumber) throw Error(ErrorMessage.INVALID_ORDER_ID);
      const invoiceCase = await getOrderFetcher({ orderNumber });

      if (!isCreatedOrder(invoiceCase)) {
        throw Error(`Type Order was not found`);
      }
      // If the case is canceled and discarded, throw an error.
      // Discarded cases cannot be invoiced, as per the business.
      const isCancelDiscard =
        invoiceCase &&
        invoiceCase.status === OrderStatus.Cancelled &&
        invoiceCase.statusReason === CancelActivity.Discard;
      if (isCancelDiscard) {
        throw Error(ErrorMessage.CANCELED_DISCARDED_CASE);
      }

      if (checkCaseInvalidInvoiceStatus(invoiceCase.status, invoiceCase.statusReason)) {
        throw Error(
          `You can't invoice this case because it is in ${getCaseStatusRecord(invoiceCase.status).displayName} status.`
        );
      }

      /**
       * Set cases and shipping before fetching shipping options since that request takes awhile and the UI needs to
       * react to some data on the case sooner.
       */
      invoiceCase.enclosedItems = filterEnclosedItemsByShippingType(
        invoiceCase.enclosedItems,
        EnclosedItemCategory.Outbound
      );
      return invoiceCase;
    },
    [getOrderFetcher]
  );

  /**
   * re-fetches the orders basing on order number provided
   */
  const onRefreshOrder = async () => {
    try {
      const invoiceCase = await onFetchOrder(orderNumber);
      if (!invoiceCase) return;
      const newInvoiceCases = invoiceCases.map(caseItem => {
        if (caseItem.orderNumber === orderNumber) {
          return invoiceCase;
        }
        return caseItem;
      });

      const filteredCases = newInvoiceCases.map(order => {
        order.enclosedItems = filterEnclosedItemsByShippingType(
          invoiceCase.enclosedItems,
          EnclosedItemCategory.Outbound
        );
        return order;
      });
      setCases(filteredCases);

      // Refreshes product return requirements.
      await refreshProductReturnRequirements(filteredCases);
    } catch (err) {
      const error = err as Error;
      toast.notify(error.message, ToastNotificationType.Error);
    }
  };

  /**
   * fetches invoice case and applies fetched values to state
   */
  useEffect(() => {
    const fetchInvoicingDetail = async () => {
      try {
        const invoiceCase = await onFetchOrder(orderNumber);

        if (location.state) {
          location.state.previousScannedInvoiceCase = invoiceCase;
        }

        const newCasesToSet = [invoiceCase];
        setCases(newCasesToSet);
        // Refreshes product return requirements.
        await refreshProductReturnRequirements(newCasesToSet);

        setShipping(invoiceCase.shipping);

        const account = await invoiceAccountLookupFetcher({ billingAccountId: invoiceCase.billingAccountId || '' });
        if (account.__typename !== 'Account') throw Error(`Type Account was not found`);

        // Sets the initial COD amount for this invoice case.
        const codAmountForAllOrders = sumBy(newCasesToSet, invoiceCase => invoiceCase.shipping?.codAmount || 0);
        const codAmountToSet = getOrderCodAmount(
          account.finance?.codAmount ?? 0,
          codAmountForAllOrders,
          invoiceCase.status
        );
        setCodAmount(codAmountToSet);

        setAccount(account);

        const manufacturingLocation = getManufacturingLocation(invoiceCase.originFacilityId || 10);
        if (!manufacturingLocation) throw Error(`Manufacturing Location was not found`);

        setUTCConversionTimeZone(manufacturingLocation.manufacturingFacilityTimeZoneId);

        const shippingOptionsData = await fetchShippingOptions({
          invoiceCase,
          account,
          subtotalAmount: invoiceCase.subtotalAmount || 0,
          initialCodAmount: codAmountToSet,
        });
        setShippingOptions(shippingOptionsData);
      } catch (err) {
        const error = err as Error;
        setInvoiceError(error);
      }
    };

    // Only make request if the order number changes.
    if (orderNumber && orderNumber !== prevOrderNumber) {
      fetchInvoicingDetail();
    }
  }, [
    fetchShippingOptions,
    getManufacturingLocation,
    invoiceAccountLookupFetcher,
    location.state,
    onFetchOrder,
    orderNumber,
    prevOrderNumber,
    refreshProductReturnRequirements,
    setAccount,
    setCases,
    setShipping,
    setShippingOptions,
    setCodAmount,
  ]);

  /**
   * triggered when invoicing is cancelled
   * triggers an analytics event, navigates back to previous page
   */
  const cancelHandler = () => {
    AnalyticsService.track(AnalyticsEventName.InvoiceCanceled);
    navigate(-1);
  };

  /**
   * Processes and return shipping overrides for this invoice, if any exist.
   * Returns the overwritten shipping fields for this invoice, if any exist.
   */
  const getShippingOverrides = (): OverrideFieldInput[] => {
    const overrides = [];
    const { carrierPreference, servicePreference, isSignatureRequiredPreference } = invoiceShippingInput;
    // If this account has an active preferred carrier, checks to see if any preferences have been overwritten.
    if (carrierPreference) {
      const isCarrierOverride = carrierPreference && invoiceShippingInput.carrier !== carrierPreference;
      if (isCarrierOverride) {
        overrides.push({
          field: 'carrier',
          type: OverrideFieldType.ShippingPreference,
          old: carrierPreference,
          new: invoiceShippingInput.carrier,
        });
      }
      const isSignatureRequiredOverride =
        isSignatureRequiredPreference !== null &&
        invoiceShippingInput.isSignatureRequired !== isSignatureRequiredPreference;
      if (isSignatureRequiredOverride) {
        overrides.push({
          field: 'signatureRequired',
          type: OverrideFieldType.ShippingPreference,
          old: isSignatureRequiredPreference ? 'yes' : 'no',
          new: invoiceShippingInput.isSignatureRequired ? 'yes' : 'no',
        });
      }

      const saturdayEntryFound = doNotShipOnSaturday();
      if (saturdayEntryFound && invoiceShippingInput.isSaturdayDelivery) {
        overrides.push({
          field: 'saturdayDelivery',
          type: OverrideFieldType.ShippingPreference,
          old: 'no',
          new: 'yes',
        });
      }

      const isServiceOverride =
        !isCarrierOverride && servicePreference && invoiceShippingInput.shippingService !== servicePreference;
      if (servicePreference && isServiceOverride) {
        overrides.push({
          field: 'shippingService',
          type: OverrideFieldType.ShippingPreference,
          old: servicePreference,
          new: invoiceShippingInput.shippingService,
        });
      }
    }
    return overrides;
  };

  /**
   * Validates the invoice data to make sure we are able to submit the invoice.
   */
  const isInvoiceValid = useCallback(() => {
    // Validates special order parts
    let specialOrderPartsErrorMessage = '';
    invoiceCases.forEach(invoiceCase => {
      const invoiceCaseErrorMessage = validateOrderItemsSpecialOrderParts(invoiceCase.orderItems);
      if (!specialOrderPartsErrorMessage && invoiceCaseErrorMessage) {
        specialOrderPartsErrorMessage = invoiceCaseErrorMessage;
      }
    });

    const isValidDiscountAmount = checkTotalDiscountAmount(invoiceCases);
    if (!isValidDiscountAmount) {
      toast.notify(ErrorMessage.TOTAL_DISCOUNT_AMOUNT_ERROR_MESSAGE, ToastNotificationType.Error);
      return false;
    }

    if (spiltBundleCaseInfo.isBundle) {
      const missingBundledOrders = spiltBundleCaseInfo.bundledOrders.filter(order => {
        return !invoiceCases.find(cases => cases.orderNumber === order.orderNumber);
      });
      if (missingBundledOrders.length) {
        const singleOrPlural = missingBundledOrders.length > 1 ? 'cases' : 'case';
        const verb = missingBundledOrders.length > 1 ? 'are' : 'is';
        const concatOrderNumberString = concatOrderNumber(missingBundledOrders);
        toast.notify(
          `Missing bundled ${singleOrPlural}! "${concatOrderNumberString}" bundled ${singleOrPlural} ${verb} missing.`,
          ToastNotificationType.Error
        );
        return false;
      }
    }
    if (specialOrderPartsErrorMessage) {
      alert.show('Missing Implant Part Value', specialOrderPartsErrorMessage);
      return false;
    }

    // As per LMS1-7011, a cannot bundle error message should be shown for both outbound and split bundles.
    const isShippingBundle = !!invoiceShippingInput.trackingNumberBundledOrder || invoiceCases.length > 1;
    if (isShippingBundle && someCasesHaveMetalArticulator(invoiceCases)) {
      alert.show(
        'Cannot bundle these cases',
        'Cases with metal articulators cannot be bundled. Remove any cases with metal articulators and invoice them separately.'
      );

      return false;
    }

    /**
     * validates if patient details are valid for submission
     */
    const isPatientInfoValid = () => {
      return invoiceCases.every(invoiceCase => {
        return !!invoiceCase.patientFirstName || !!invoiceCase.patientLastName || !!invoiceCase.patientId;
      });
    };

    /**
     * validates if case is not valid for submission
     *
     * @see {@link INVALID_INVOICE_STATUSES} for all invalid cases
     */
    const isCaseStatusValid = () => {
      return invoiceCases.every(invoiceCase => !INVALID_INVOICE_STATUSES.includes(invoiceCase.status));
    };

    /**
     * validates if case has valid shipping options selected for submission
     */
    const isShippingValid = () => {
      return (
        !!invoiceShippingInput.carrier && !!invoiceShippingInput.packageType && !!invoiceShippingInput.shippingService
      );
    };

    let isEnclosedItemsValid = true;
    invoiceCases.forEach(invoiceCase => {
      const validateEnclosedItems = invoiceCase.enclosedItems.every(item => {
        return item.itemCode && item.quantity;
      });
      if (!validateEnclosedItems) {
        const enclosedItems = invoiceCase.enclosedItems.map(item => {
          return {
            ...item,
            errors: {
              itemCode: !item.itemCode ? 'Please select a enclosed item code' : '',
              quantity: !item.quantity ? 'Please enter a quantity' : '',
            },
          };
        });
        invoiceCase.enclosedItems = enclosedItems;
        isEnclosedItemsValid = false;
      }
    });

    if (!isEnclosedItemsValid) {
      setCases([...invoiceCases]);
    } else {
      invoiceCases.forEach(invoiceCase => {
        invoiceCase.enclosedItems.forEach((item: LocalOrderEnclosedItem) => {
          delete item.errors;
        });
      });
    }

    if (!isPatientInfoValid() || !isCaseStatusValid() || !isShippingValid() || !isEnclosedItemsValid) {
      alert.show('Missing Required Fields', 'Please make sure all required fields are completed before submitting.');
      return false;
    }

    const isValidMetalAlloyAttributes = invoiceCases.every(invoiceCase => {
      return validateMetalAlloyAttributes(invoiceCase.orderItems);
    });

    if (!isValidMetalAlloyAttributes) {
      toast.notify(ErrorMessage.MISSING_ALLOY_TYPE_OR_WEIGHT, ToastNotificationType.Error);
      return false;
    }

    return true;
  }, [
    alert,
    invoiceCases,
    invoiceShippingInput.carrier,
    invoiceShippingInput.packageType,
    invoiceShippingInput.shippingService,
    invoiceShippingInput.trackingNumberBundledOrder,
    setCases,
    spiltBundleCaseInfo.bundledOrders,
    spiltBundleCaseInfo.isBundle,
    toast,
  ]);

  /**
   * function which handles submission of case for invoicing
   *
   * @param printLabel - whether to print
   */
  const submitHandler = async (printLabel: boolean) => {
    setOnSubmitAlertCount(onSubmitAlertCount + 1);

    if (!isInvoiceValid()) return;

    const selectedShippingData = getSelectedShippingData();
    const selectedShippingOptionFromData = selectedShippingData.selectedShippingOption;
    const outboundShippingCharge = selectedShippingData.outboundShippingCharge;
    const codFee = selectedShippingData.codFee;

    if (!selectedShippingOptionFromData || !uTCConversionTimeZone || !orderNumber) {
      toast.notify('Selected Shipping Option not found.', ToastNotificationType.Error);
      setInvoiceShippingInput({
        carrier: '',
        packageType: null,
        shippingService: '',
      });
      return;
    }

    const isDoctorPickUp = selectedShippingOptionFromData.carrier === ShippingCarrier.DoctorPickup;

    if (!isDoctorPickUp && !outboundShippingCharge) {
      toast.notify('Selected Shipping Option not found.', ToastNotificationType.Error);
      setInvoiceShippingInput({
        carrier: '',
        packageType: null,
        shippingService: '',
      });
      return;
    }

    const buildCharge = (
      shippingCharge: Charge,
      cost: number,
      type: ShippingChargeType,
      serviceOverride?: string
    ): InvoiceShippingCharge => {
      let discountAmount = 0;
      // If the type is outbound, calculate the discount amount.
      if (type === ShippingChargeType.Outbound) {
        discountAmount = getOutboundDiscountAmount(cost, selectedShippingData.outboundDiscountPercentage);
      }
      // totalAmount represents the total cost of the shipping charge after the discount has been applied.
      const totalAmount = roundDecimal(cost - discountAmount);
      return {
        amount: cost,
        carrier: selectedShippingOptionFromData.carrier,
        discountAmount: discountAmount,
        id: shippingCharge.shippingChargeId,
        salesTaxAmount: 0,
        service: serviceOverride || selectedShippingOptionFromData.service,
        totalAmount: totalAmount,
        type: type,
        taxCode: shippingCharge.taxCode,
      };
    };

    const shippingCharges = [
      buildCharge(outboundShippingCharge, selectedShippingData.outboundShippingChargeCost, ShippingChargeType.Outbound),
    ];

    const codFeePrice = codFee?.currencies.find(currency => currency.currencyCode === account?.currencyCode)?.price;
    if (codFee && codFeePrice) {
      shippingCharges.push(buildCharge(codFee, codFeePrice, ShippingChargeType.Cod, ShippingService.COD_FEE));
    }

    if (selectedShippingData.signatureRequiredCharge) {
      shippingCharges.push(
        buildCharge(
          selectedShippingData.signatureRequiredCharge,
          selectedShippingData.signatureRequiredCost,
          ShippingChargeType.Outbound,
          selectedShippingData.signatureRequiredCharge.service
        )
      );
    }

    if (selectedShippingData.saturdayDeliveryCharge) {
      shippingCharges.push(
        buildCharge(
          selectedShippingData.saturdayDeliveryCharge,
          selectedShippingData.saturdayDeliveryCost,
          ShippingChargeType.Outbound,
          selectedShippingData.saturdayDeliveryCharge.service
        )
      );
    }

    const deliveryMethod = isDoctorPickUp ? DeliveryMethod.DoctorPickup : DeliveryMethod.Carrier;

    const invoiceShipping: InvoiceShipping = {
      packageType: selectedShippingOptionFromData.package,
      deliveryMethod,
      doctorPickupLocationId: isDoctorPickUp ? invoiceCases[0].originFacilityId : null,
    };

    // If there is a cod amount, adds it to the invoice shipping request object.
    if (codAmount) {
      invoiceShipping.codAmount = codAmount;
    }

    if (isDoctorPickUp) {
      const { number: phoneToUseNumber, type: phoneToUseType } = getPhoneToUse(account?.phoneNumbers || []) || {};
      // The services team has requested that we not send them phone information if the phone number is blank. Please see LMS1-7140.
      if (phoneToUseNumber) {
        invoiceShipping.phone = {
          number: phoneToUseNumber,
          type: phoneToUseType ?? PhoneType.Home,
        };
      }
    }

    const trackingNumberBundledOrder = invoiceShippingInput.trackingNumberBundledOrder;

    /**
     * Only add data if we are not bundling with tracking number or
     * if the tracking number we are bundling with is not charge eligible.
     * For not charge eligible case, reuse the rateId, shipmentId, and shipping charges so
     * that we do not purchase a new label.
     */
    if (!trackingNumberBundledOrder || !trackingNumberBundledOrder.isChargeEligible) {
      const bundleCharges = trackingNumberBundledOrder?.shippingCharges.map(charge => ({
        ...charge,
        salesTaxAmount: charge.salesTaxAmount || 0,
        discountAmount: getOutboundDiscountAmount(charge.amount, selectedShippingData.outboundDiscountPercentage),
      }));
      invoiceShipping.rateId = trackingNumberBundledOrder?.rateId || selectedShippingOptionFromData.rateId;
      invoiceShipping.shipmentId = trackingNumberBundledOrder?.shipmentId || selectedShippingOptionFromData.shipmentId;
      invoiceShipping.shippingCharges = !isDoctorPickUp ? bundleCharges || shippingCharges : [];
    }

    const invoiceInput: InvoiceInput = {
      invoiceBy: currentUserName,
      orderInvoices: [],
      uTCConversionTimeZone,
    };

    // Added bundleOrderNumber if we are bundling with existing tracking number.
    if (trackingNumberBundledOrder) {
      invoiceInput.bundledOrderNumber = trackingNumberBundledOrder.orderNumber;
    }

    const shippingApplicableNextCaseIndex = getOutboundApplicableIndex(invoiceCases, productReturnRequirements || []);

    // Create invoice cases for input. Only add shipping to the charge eligible case (if it exists).
    const invoiceInputCases = invoiceCases.map(
      ({ orderNumber: invoiceCaseOrderNumber, coupons, enclosedItems }, index) => ({
        orderNumber: invoiceCaseOrderNumber,
        coupons,
        enclosedItems,
        shipping:
          index === shippingApplicableNextCaseIndex // Only add charges to the charge eligibile case.
            ? invoiceShipping
            : getDefaultShipping(deliveryMethod, invoiceShippingInput.packageType),
      })
    );

    /**
     * If there is no case found that can apply outbound shipping costs,
     * and we are not bundling with tracking number (don't discount case if we already have a case with charges):
     * attach full shipping to first case with no charge (discount equal to total for all charges).
     * Originally implemented to support RMA cases.
     */
    if (shippingApplicableNextCaseIndex < 0 && !trackingNumberBundledOrder) {
      invoiceShipping.shippingCharges = invoiceShipping.shippingCharges?.map(charge => ({
        ...charge,
        discountAmount: charge.amount,
      }));
      invoiceInputCases[0].shipping = invoiceShipping;
    }

    invoiceInput.orderInvoices = invoiceInputCases;

    // Processes and return shipping overrides for this invoice, if any exist.
    invoiceInput.overrides = getShippingOverrides();
    overlayLoader.show('Creating invoice');

    let isInvoiceCreated = false;
    try {
      await invoiceFetcher(invoiceInput);
      AnalyticsService.track(AnalyticsEventName.CaseInvoiced, invoiceInput);
      overlayLoader.hide();
      isInvoiceCreated = true;
    } catch {
      overlayLoader.hide();
      toast.notify('Failed to create invoice.', ToastNotificationType.Error);
    }

    if (!isInvoiceCreated) return;

    const navState: InvoiceSummaryRouteState = {
      orders: invoiceCases,
    };

    if (printLabel) {
      navState.printLabelData = {
        orderNumber,
        type: invoiceShipping.deliveryMethod || DeliveryMethod.Carrier,
        printInboundLabel: isAccountOnCod(account?.standing),
      };
    }

    const designImagingSpotOpposings = invoiceCases
      .map(invoiceCase =>
        invoiceCase.fileAttachments.find(
          attatchment => attatchment.uploadProperties.fileType === 'DesignImageSpotOpposing'
        )
      )
      .filter(Boolean);

    if (navState.printLabelData && designImagingSpotOpposings.length) {
      navState.printLabelData.opposingImageFilePaths = designImagingSpotOpposings.map(
        attachment => attachment?.fileUrl || ''
      );
    }

    if (spiltBundleCaseInfo.bundleId) {
      navState.bundleId = spiltBundleCaseInfo.bundleId;
    }

    navigate(
      {
        pathname: ROUTES.INVOICE_ORDER_LOOKUP,
      },
      {
        state: navState,
      }
    );
  };

  /**
   * get the title of the invoicing page based on the bundle case info.
   */
  const invoicingHeaderTitle = useMemo(() => {
    if (!spiltBundleCaseInfo.isBundle) return 'Invoicing';
    return `Invoicing Bundle ${spiltBundleCaseInfo.bundleId}`;
  }, [spiltBundleCaseInfo]);

  const isOrderLoading = (orderLoading && !invoiceInitialized) || accountLoading;
  /**
   * Ensure that shipping and order is both availabel before we allow submission
   */
  const isSubmissionAvailable = isOrderLoading || shippingLoading;

  /**
   * If there are orders scanned, add a case for each order to the invoiceCases List.
   */
  useEffect(() => {
    if (!ordersScanned.length || isOrderLoading) return; // If there are no orders scanned or invoice is loading, do nothing.
    const filteredOrdersScanned: CreatedOrder[] = ordersScanned.filter((order: CreatedOrder) => {
      const isOrderAlreadyInInvoice = invoiceCases.find(({ orderNumber }) => orderNumber === order.orderNumber);
      return !isOrderAlreadyInInvoice;
    });

    if (!filteredOrdersScanned.length) return; // If there are no orders to add, do nothing.
    const scannedOrderNumberPromises = filteredOrdersScanned.map(({ orderNumber }) => onFetchOrder(orderNumber));

    const fetchMultipleOrders = async () => {
      try {
        const scannedOrders = await Promise.all(scannedOrderNumberPromises);
        scannedOrders.forEach(scannedOrder => {
          if (!scannedOrder) return;
          newCase(scannedOrder);
        });
      } catch (err) {
        const error = err as Error;
        console.error('Error while fetching scanned orders: ', error);
        toast.notify(
          `Error while fetching scanned orders: ${concatOrderNumber(filteredOrdersScanned)}`,
          ToastNotificationType.Error
        );
      }
    };
    fetchMultipleOrders();
  }, [ordersScanned, isOrderLoading, newCase, invoiceCases, onFetchOrder, toast]);

  /**
   *
   * React useEffect hook to automatically refresh product return requirements when the number of invoice cases changes.
   * - Calls 'refreshProductRequirement' if the current invoice cases are greater than previous invoice cases length which means a new case is added.
   *
   */
  useEffect(() => {
    if (prevInvoiceCase?.length && invoiceCases.length && invoiceCases.length > prevInvoiceCase.length) {
      refreshProductReturnRequirements(invoiceCases);
    }
  }, [prevInvoiceCase, invoiceCases, refreshProductReturnRequirements]);

  if (invoiceError) {
    return (
      <ModuleContainer>
        <ModuleHeader title={invoicingHeaderTitle}></ModuleHeader>
        <div className="flex flex-col items-center justify-center h-full">
          <div className="shadow-md rounded-md bg-white py-12 px-6 my-4 w-full flex flex-col gap-4">
            <div className="text-2xl font-semibold text-red-600 text-center">
              Error occurred while loading the case.
            </div>
            <div className="text-md font-semibold text-red-600 text-center">{invoiceError.message}</div>
          </div>
        </div>
      </ModuleContainer>
    );
  }

  const isOrderProcessing = checkPriceOrTaxBeingCalculated(invoiceCases[0]?.metadata);

  return (
    <ModuleContainer>
      <InvoiceHeader
        isLoading={isSubmissionAvailable}
        title={invoicingHeaderTitle}
        onCancel={cancelHandler}
        onSubmit={submitHandler}
        onRefresh={onRefreshOrder}
        isOrderProcessing={isOrderProcessing}
      />
      <div className="flex overflow-y-scroll bg-gray-50">
        <InvoiceDetail
          isLoading={isOrderLoading}
          isShippingLoading={shippingLoading}
          fetchShippingOptions={fetchShippingOptions}
          onRefresh={onRefreshOrder}
        />
        <RightPanel isLoading={isInvoiceSummaryLoading} productReturnRequirements={productReturnRequirements || []} />
      </div>
    </ModuleContainer>
  );
};

export default InvoicingPage;
