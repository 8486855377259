import { ChevronDownIcon, EllipsisVerticalIcon, PencilIcon } from '@heroicons/react/24/solid';
import { OrderStatus } from 'API';
import BreadCrumbs from 'components/common/BreadCrumbs';
import DropdownMenuButton from 'components/common/DropdownMenuButton';
import { RefreshButton } from 'components/common/RefreshButton/RefreshButton';
import { RestrictedContent } from 'components/common/RestrictedContent/RestrictedContent';
import { ROUTES } from 'components/navigation/Constants';
import { AlertModalContext } from 'providers/AlertModalProvider';
import { useCaseDetail } from 'providers/CaseDetailModuleProvider';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CancelActivity, KebabMenuType, kebabMenus, printMenus } from 'shared/constants/case-detail.constants';
import { PERMISSION_DENIED, PERMISSION_DENIED_TEXT } from 'shared/constants/role-based-access-control';
import { ACTIONS } from 'shared/enums/permission';
import { navigateToInvoiceHelper } from 'shared/helpers/invoice/invoice.helper';
import { isLMSOrder, orderLocked } from 'shared/helpers/order-detail/order-detail.helper';
import { useRoleBasedAccessControl } from 'shared/hooks/useRoleBasedAccessControl';
import { NavigationPage } from 'shared/models';
import { getSpiltBundleCaseInfo } from 'shared/utils';

/**
 * Props for the OrderDetailHeader component.
 */
interface OrderDetailHeaderProps {
  /** The ID of the order. */
  orderId: string;
  /** Function to handle print menu item click. */
  onPrintMenuClick: (menu: string) => void;
  /** Function to handle kebab menu item click. */
  onKebabMenuClick: (menu: string) => void;
  /** Function to handle edit case button click. */
  onEditCase: () => void;
  /** Function to handle restore case button click. */
  onRestoreCase: () => void;
  /** The search value used for navigation. */
  searchValue: string;
  /** Function to handle refresh button click. */
  onRefresh: () => void;
  /** Indicates whether the order is currently being processed. */
  isOrderProcessing?: boolean;
}

/**
 * Header component for the order detail page.
 * Displays the order ID, navigation breadcrumbs, action buttons, and menus.
 * @param orderId - The ID of the order.
 * @param onPrintMenuClick - Function to handle print menu item click.
 * @param onKebabMenuClick - Function to handle kebab menu item click.
 * @param onEditCase - Function to handle edit case button click.
 * @param onRestoreCase - Function to handle restore case button click.
 * @param searchValue - The search value used for navigation.
 * @param onRefresh - Function to handle refresh button click.
 * @param isOrderProcessing - Indicates whether the order is currently being processed.
 */
const OrderDetailHeader: React.FC<OrderDetailHeaderProps> = ({
  orderId,
  onPrintMenuClick,
  onKebabMenuClick,
  onEditCase,
  children,
  searchValue,
  onRefresh,
  isOrderProcessing,
}) => {
  const prevNavRoot = searchValue.length ? `${ROUTES.SEARCH_CASE_PATH}?term=${searchValue}` : ROUTES.SEARCH_CASE_PATH;
  const pages: NavigationPage[] = [
    {
      title: 'Back to search results',
      href: prevNavRoot,
      current: false,
      routeState: {
        previousSearch: searchValue,
      },
    },
    { title: orderId, href: '', current: true },
  ];
  const { caseDetails: order } = useCaseDetail();
  const navigate = useNavigate();
  const invoicePermission = useRoleBasedAccessControl(ACTIONS.INVOICE_CASE);
  const alert = useContext(AlertModalContext);
  // As per LMS1-7641, the user should not be able to interact with orders that are processing.

  const orderStatus = order?.status;
  const isOrderCreditedOrCreditPending =
    orderStatus === OrderStatus.CreditPending || orderStatus === OrderStatus.Credited;

  // In LMS1-7946, we established that the buttons on the case details screen should be hidden for credit pending/credited orders.
  const isProcessedLMSOrder =
    !isOrderProcessing && isLMSOrder(order?.originatingSystem) && !isOrderCreditedOrCreditPending;

  const onInvoiceClick = () => {
    if (invoicePermission.allowAll) {
      navigateToInvoiceHelper(order, navigate);
    } else {
      alert.show(PERMISSION_DENIED, PERMISSION_DENIED_TEXT);
    }
  };

  const filterKebabs = useCallback(() => {
    if (orderStatus) {
      return [...kebabMenus]
        .filter(item => [...item.visibilities].includes(orderStatus))

        .map(item => {
          let isDisabled = false;
          if (item.id === KebabMenuType.UnInvoice) {
            /**
             * In LMS1-8076, we established that the uninvoice option should be available for any invoice pending order and disabled otherwise.
             */
            isDisabled = orderStatus !== OrderStatus.InvoicePending;
          } else if (item.id === KebabMenuType.CancelCase) {
            /**
             * Disabled the Cancel Case option if the order is bundled with another order
             */
            isDisabled = getSpiltBundleCaseInfo(order).isBundle;
          }

          return {
            ...item,
            disabled: isDisabled,
          };
        });
    } else {
      return [...kebabMenus];
    }
  }, [order, orderStatus]);

  const filterPrintMenus = useCallback(() => {
    if (order?.status) {
      return [...printMenus].filter(item => [...item.visibilities].includes(order.status));
    } else {
      return [...kebabMenus];
    }
  }, [order]);

  const withinWindow = (date: string) => {
    const window = new Date(date);
    window.setDate(window.getDate() + 14);
    return window > new Date();
  };

  return (
    <>
      <div className="items-center justify-between relative z-10 flex-shrink-0 bg-white px-6 pt-4 border-b border-gray-200">
        <BreadCrumbs pages={pages} />
        <div className="flex my-2 gap-3">
          <h2 className="flex-grow text-3xl font-bold text-gray-900">{orderId}</h2>
          {order?.status === OrderStatus.Deleted ? (
            <>
              {/**  restore case feature was cancelled, for now no one has permission to restore so the button is always hidden */}
              {order.updatedDate && withinWindow(order.updatedDate) && (
                <RestrictedContent permission={ACTIONS.RESTORE_DELETED_CASE}>
                  {/* <button
                    type="button"
                    className="inline-flex items-center px-3 py-2 shadow-sm text-sm leading-4 font-medium rounded-md text-indigo-600 bg-indigo-50 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    data-qa="editCaseButton"
                    onClick={onRestoreCase}
                  >
                    <ArrowPathIcon className="h-4 mr-1" />
                    Restore Case
                  </button> */}
                </RestrictedContent>
              )}
            </>
          ) : order?.status === OrderStatus.Cancelled ? (
            <>
              {order.statusReason !== CancelActivity.Discard && (
                <button
                  className="text-sm py-2 px-4 text-center font-medium rounded-md text-gray-900 bg-white border border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={onInvoiceClick}
                >
                  Invoice Case
                </button>
              )}
              {/* TODO: Will be added in 2.0. */}
              {/* <button
                type="button"
                className="inline-flex items-center px-3 py-2 shadow-sm text-sm leading-4 font-medium rounded-md text-indigo-600 bg-indigo-50 hover:bg-indigo-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                data-qa="editCaseButton"
                onClick={onRestoreCase}
              >
                <PlayCircleIcon className="h-4 mr-1" />
                Reactivate Case
              </button> */}
            </>
          ) : (
            <>
              {isOrderProcessing && (
                <div>
                  <RefreshButton onClick={onRefresh} />
                </div>
              )}
              {isProcessedLMSOrder && (
                <DropdownMenuButton
                  menuItems={filterKebabs()}
                  onMenuClick={onKebabMenuClick}
                  rightIcon={<EllipsisVerticalIcon className="h-5" aria-label="dots-vertical" />}
                  onFocus={() => onKebabMenuClick('')}
                />
              )}
              {isProcessedLMSOrder && (
                <DropdownMenuButton
                  caption="Print"
                  menuItems={filterPrintMenus()}
                  onMenuClick={onPrintMenuClick}
                  rightIcon={<ChevronDownIcon className="h-4 w-4" aria-label="chevron-down" />}
                />
              )}
              {isProcessedLMSOrder && !orderLocked(order?.status) && (
                <button
                  type="button"
                  className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  data-qa="editCaseButton"
                  onClick={onEditCase}
                >
                  <PencilIcon className="h-4 mr-1" />
                  Edit
                </button>
              )}
            </>
          )}
        </div>
        {children}
      </div>
    </>
  );
};

export default OrderDetailHeader;
