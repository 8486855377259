import { AddressType } from 'API';
import { CaseStatusBadge } from 'components/common/CaseStatusBadge/CaseStatusBadge';
import { TableColumn } from 'components/common/Table/types';
import { Link } from 'react-router-dom';
import { LocalOrderInput, LocalOrderType } from 'shared/models';
import { getDateAndTimeInLongFormat } from 'shared/utils';
import { CaseHistoryOrder } from 'types/case-history';

export const orderHistoryColumns: TableColumn<CaseHistoryOrder>[] = [
  {
    id: 'orderNumber',
    name: 'Order #',
    render: ({ row }) => (
      <Link to={`/orders/${row.orderNumber}`} className="text-indigo-600 hover:text-indigo-900" data-qa="orderIdLink">
        {row.orderNumber}
      </Link>
    ),
  },
  {
    id: 'updatedDate',
    name: 'Updated Date',
    render: ({ row }) => {
      // Formats the updated/created date, preferably using utcConversionTimeZoneCode.
      return row.createdDate
        ? getDateAndTimeInLongFormat(row.updatedDate || row.createdDate, row.utcConversionTimeZoneCode)
        : '';
    },
  },
  {
    id: 'status',
    name: 'Status',
    render({ row }) {
      return <CaseStatusBadge status={row.status} />;
    },
  },
];

export const OrderNumberRegex = new RegExp('^((rma|mp)\\d\\-)?\\d{1,}$', 'i');

/**
 * These are the initial values for the new order input
 */
export const INITIAL_NEW_ORDER_INPUT_DATA: LocalOrderInput = {
  orderNumber: 'New Case',
  originalOrderNumber: '',
  billingAccountId: '',
  providerId: '',
  providerName: '',
  patientFirstName: '',
  patientLastName: '',
  patientId: '',
  enclosedItems: [],
  fileAttachments: [],
  orderItems: [
    {
      id: '',
      quantity: 1,
      productCode: '',
      productName: '',
      coupons: [],
      services: [],
      addOns: [],
      attributes: [],
      daysInLab: 0,
      manufacturingLocation: '',
      manufacturingLocationId: 0,
      material: '',
      restorationType: '',
      department: '',
    },
  ],
  inboundTrackingNumbers: [],
  shippingAddress: {
    street1: '',
    street2: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    type: AddressType.Shipping,
  },
  coupons: [],
  notes: '',
  utcConversionTimeZone: '',
  createdBy: '',
  originFacility: '',
  originFacilityId: -1,
  updatedBy: '',
  localMetadata: {
    localOrderType: LocalOrderType.Standard,
  },
};

/**
 * these are keys we used to validate the order input when we add or update the order
 */
export const ORDER_INPUT_VALIDATION_KEYS: Array<keyof LocalOrderInput> = [
  'orderNumber',
  'originalOrderNumber',
  'billingAccountId',
  'providerId',
  'providerName',
  'patientFirstName',
  'patientLastName',
  'patientId',
  'enclosedItems',
  'fileAttachments',
  'orderItems',
  'inboundTrackingNumbers',
  'shippingAddress',
  'coupons',
  'notes',
  'originFacility',
  'originFacilityId',
  'localMetadata',
];
