import { Table } from 'components/common/Table/Table';
import { useNavigate } from 'react-router-dom';
import { orderHistoryColumns } from 'shared/constants/order.constants';
import { getOrderDetailPath } from 'shared/helpers/route.helper';
import { CaseHistoryOrder } from 'types/case-history';

interface IProps {
  orders: CaseHistoryOrder[];
  loading?: boolean;
}

/**
 * Component to present a history of recently accessed case records.
 * @returns - A JSX element representing the TaskHistory component
 */
const TaskHistory: React.FC<IProps> = ({ orders, loading }) => {
  const navigate = useNavigate();

  /**
   * Handles click on a row in the table.
   * Navigates to the detail page of the clicked order.
   * @param row - The clicked row object representing a searched order.
   */
  const rowClick = (row: CaseHistoryOrder) => {
    navigate(getOrderDetailPath(row.orderNumber));
  };

  return (
    <>
      <div className="flex gap-4">
        <h2 className="text-xl font-bold text-gray-900 mt-0.5">My Case History</h2>
      </div>

      <Table
        rows={orders || []}
        columns={orderHistoryColumns}
        loading={loading}
        maxHeight="full"
        handleRowClick={rowClick}
        hoverRow
      />
    </>
  );
};

export default TaskHistory;
