import Images from '../../assets/images';

export interface ILab {
  id: number;
  name: string;
  logo: string;
  facilityId: number;
}

const {
  // AllLabsLogo,
  // CrownWorldLogo,
  // EdgelineLogo,
  RiversideLogo,
  // GulfStarLogo,
  // HubRxLogo,
  // PacificEdgeLogo
} = Images;

/**
 * list of available laboratories
 */
export const LABS: ILab[] = [
  {
    id: 1,
    facilityId: 30,
    name: 'Riverside Dental Ceramics',
    logo: RiversideLogo,
  },
  // {
  //   id: 1,
  //   name: 'All Labs',
  //   logo: AllLabsLogo
  // },
  // {
  //   id: 2,
  //   name: 'Crown World',
  //   logo: CrownWorldLogo
  // },
  // {
  //   id: 3,
  //   name: 'EdgeLine',
  //   logo: EdgelineLogo
  // },
  // {
  //   id: 4,
  //   name: 'Glidewell',
  //   logo: GlidewellLogo
  // },
  // {
  //   id: 5,
  //   name: 'Glidewell Chile',
  //   logo: GlidewellLogo
  // },
  // {
  //   id: 6,
  //   name: 'Glidewell Tijuana',
  //   logo: GlidewellLogo
  // },
  // {
  //   id: 7,
  //   name: 'Gulf Star',
  //   logo: GulfStarLogo
  // },
  // {
  //   id: 8,
  //   name: 'HubRx',
  //   logo: HubRxLogo
  // },
  // {
  //   id: 9,
  //   name: 'Pacific Edge',
  //   logo: PacificEdgeLogo
  // }
];
