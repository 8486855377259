import {
  BaseOrderItem,
  Charge,
  Coupon,
  DeliveryMethod,
  DiscountType,
  EnclosedItemCategory,
  InvoiceShipping,
  ManufacturingLocation,
  Order,
  OrderBundle,
  OrderEnclosedItem,
  OrderItem,
  OrderProductAttribute,
  OrderStatus,
  PackageType,
  ProductComponent,
  ProductComponentType,
  ReturnType,
  RmaOrderItem,
  SearchedOrderItem,
  ShippingOption,
} from 'API';
import { DisplayDiscountItemTooltip } from 'components/SpecialDiscount/DisplayDiscountItems/DisplayDiscountItemTooltip';
import { ROUTES } from 'components/navigation/Constants';
import moment from 'moment';
import { InvoicingDetailContext } from 'providers/InvoicingDetailModuleProvider';
import { NavigateFunction } from 'react-router-dom';
import { COUPON_EXPIRED_LABEL, LAB_CUTOFF_TIME_FORMAT } from 'shared/constants/constants';
import { ARTICULATOR } from 'shared/constants/invoice.constants';
import { AttributeName, AttributeType, ShippingCarrier } from 'shared/enums';
import { SpecialOrderParts } from 'shared/enums/special-order-parts';
import {
  CreatedOrder,
  CreatedOrderItem,
  LocalOrderEnclosedItem,
  RelatedOrderItem,
  RelatedRmaOrderItem,
} from 'shared/models';
import { getDateAndTimeInShortFormat } from 'shared/utils';
import { DiscountState } from 'stores/useAddDiscountModalStore/createSpecialDiscountSlice';
import { DisplayDiscountItemType } from 'types/common';
import { RemoveTypename } from 'types/generic';
import { isCouponExpired } from '../coupon.helper';
import { getShadeAndToothStringValue } from '../order-detail/order-detail.helper';
import { ALLOY_NO_VALUE } from '../order-entry/order-entry.helper';
import { getInvoiceCreatePath } from '../route.helper';

export const BUNDLE_CASE_NUMBER_KEY = 'bundleCaseNumber';
const MOMENT_DATE_TIME_FORMAT = moment.HTML5_FMT.DATETIME_LOCAL_MS;

export const getPatientInfo = (order?: Order) => {
  let patientInfo = '';
  if (order?.patientFirstName || order?.patientLastName) {
    patientInfo = `${order?.patientFirstName ?? ''} ${order?.patientLastName ?? ''}`;
  } else if (order?.patientId) {
    patientInfo = order.patientId;
  } else {
    patientInfo = 'No Patient Info';
  }

  return patientInfo;
};

/**
 * Return color variant if the preference exists.
 * Variant is determined based whether or not the user is overriding the preference.
 * @param preferenceExists - Whether the preference exists or not.
 * @param isOverride - Whether it is a preference override or not.
 * @returns returns string color variant or undefined.
 */
export const getCheckBoxVariant = (preferenceExists: boolean, isOverride: boolean) => {
  if (preferenceExists) {
    return isOverride ? 'orange' : 'teal';
  }
  return undefined;
};

export const getDefaultOrderEnclosedItem = (
  currentUserName = 'Test',
  category?: EnclosedItemCategory
): LocalOrderEnclosedItem => {
  return {
    itemCode: '',
    quantity: 0,
    type: '',
    createdBy: currentUserName,
    createdDate: new Date().toISOString(),
    shippingType: category ?? EnclosedItemCategory.Inbound,
  };
};

export const getDefaultOrderItem = (): OrderItem => {
  return {
    __typename: 'OrderItem',
    addOns: [],
    attributes: [],
    coupons: [],
    daysInLab: 0,
    department: '',
    itemId: '',
    manufacturingLocation: '',
    manufacturingLocationId: 0,
    orderNumber: '',
    productCode: '',
    productName: '',
    productDescription: '',
    quantity: 0,
    services: [],
  };
};

export const getDefaultOrder = (): Order => {
  return {
    __typename: 'Order',
    orderNumber: '',
    billingAccountId: '',
    providerId: '',
    providerName: '',
    patientFirstName: '',
    patientLastName: '',
    patientId: '',
    enclosedItems: [],
    fileAttachments: [],
    orderItems: [],
    coupons: [],
    notes: '',
    createdBy: '',
    originFacility: '',
    originFacilityId: -1,
    status: OrderStatus.InLab,
    addresses: [],
    createdDate: new Date().toISOString(),
    utcConversionTimeZoneCode: moment.tz.guess(),
  };
};

export const getDefaultShippingOption = (): ShippingOption => {
  return {
    __typename: 'ShippingOption',
    carrier: '',
    cost: 0,
    isSaturdayDelivery: false,
    package: PackageType.Box,
    rateId: '',
    requiresSignature: false,
    service: '',
    shipmentId: '',
  };
};

/**
 * Returns the lab cut off in unix time.
 * @param manufacturingLocation - Information about the target manufacturing location.
 * @returns the lab cut off in unix time.
 */
export const getLabCutOffUnixTime = (manufacturingLocation: ManufacturingLocation | undefined): number => {
  const { invoiceCutOffTime, manufacturingFacilityTimeZoneId } = manufacturingLocation || {};
  // Returns 0 if there is no cutoff time, or if there is no timezone info.
  if (!invoiceCutOffTime || !manufacturingFacilityTimeZoneId) return 0;
  const formattedLabCutOffTime = moment(invoiceCutOffTime, LAB_CUTOFF_TIME_FORMAT).format(MOMENT_DATE_TIME_FORMAT);
  // Converts the UTC offset into a date string compatible format (i.e. -08:00).
  const timeZoneSuffix = moment.tz(manufacturingFacilityTimeZoneId).format('Z');
  // Returns the calculated UTC cutoff time.
  return moment(`${formattedLabCutOffTime}${timeZoneSuffix}`, `${MOMENT_DATE_TIME_FORMAT}Z`).utc().valueOf();
};

/**
 * Returns true if the current time is past the lab cutoff. Returns false otherwise.
 * @param labCutoffTime - The lab cut off in unix time.
 * @returns true if the current time is past the lab cutoff. Returns false otherwise.
 */
export const getIsPastCutOff = (labCutoffTime: number): boolean => {
  const currentUtcTime = moment().utc().valueOf();
  return currentUtcTime >= labCutoffTime;
};

/**
 * Determine whether the case is locked for invoicing
 * @param order - the order to test against.
 * @param manufacturingLocation - information about the manufacturing location.
 * @returns returns whether the case is locked for invoicing.
 */
export const isCaseLockedForInvoicing = (
  order: Pick<CreatedOrder, 'invoiceDate' | 'utcConversionTimeZoneCode' | 'status'>,
  manufacturingLocation: ManufacturingLocation | undefined
): boolean => {
  const { invoiceDate, status, utcConversionTimeZoneCode: orderTimeZone } = order;
  const isOrderInvoiced = !!invoiceDate;
  const isOrderInvoicedOrShipped = status === OrderStatus.Invoiced || status === OrderStatus.Shipped;
  // User's current timestamp converted to timezone.
  const userMoment = moment.tz(moment(), orderTimeZone);
  // Calculated cutoff time.
  const labCutoffTime = getLabCutOffUnixTime(manufacturingLocation);
  // Invoice timestamp converted to order timezone.
  const invoiceMoment = moment.tz(invoiceDate, orderTimeZone);
  const isSameDateAsInvoice = userMoment.isSame(invoiceMoment, 'date');
  const isPastCutOff = getIsPastCutOff(labCutoffTime);
  const sameDayAndPastCutoff = isSameDateAsInvoice && isPastCutOff;
  const isPastInvoiceDate = userMoment.isAfter(invoiceMoment, 'date');

  if (isOrderInvoiced && labCutoffTime) {
    if (isOrderInvoicedOrShipped || sameDayAndPastCutoff || isPastInvoiceDate) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getSpecialDiscountItem = (discount: DiscountState, selectedCaseId: string) => {
  const result: DisplayDiscountItemType = {
    caseId: selectedCaseId,
    discountCode: '',
    discountAmount: 0,
    discountText: '',
  };
  const { isPercentage, percentageValue, dollarAmount } = discount;
  const amount = isPercentage ? `${percentageValue}%` : `$${dollarAmount}`;
  result.discountText = `${amount} off`;
  result.discountAmount = isPercentage ? percentageValue : dollarAmount;
  return result;
};

/**
 * Returns text to display for coupon components.
 * @param components - the coupon components to parse.
 * @returns text to display for the target coupon components.
 */
export const getCouponComponentsText = (components: ProductComponent[]) => {
  const firstMaterial = components.find(component => component?.type === ProductComponentType.Material);
  const restorations = components.filter(component => component?.type === ProductComponentType.RestorationType);
  // If no restorations are found in the service response, we can still display information about the material.
  if (firstMaterial?.value && restorations.length === 0) {
    return [firstMaterial.value];
  }
  return restorations.map(restoration => `${firstMaterial?.value || ''} ${restoration.value}`.trim());
};

/**
 * Returns text to display for a given coupon.
 * This is used to populate both a coupon component and a tooltip.
 * @param coupon - the target coupon.
 * @param isMaterialInfoHidden - whether to show information about a given coupon (ignored for tooltips).
 * @returns text to display for a given coupon.
 */
export const getCouponText = (coupon: Coupon, isMaterialInfoHidden?: boolean) => {
  const { code, discountType, discountAmount, components, usageLimit, expirationDate } = coupon;

  const isPercentage = discountType === DiscountType.Percentage;
  const amount = isPercentage ? `${discountAmount}%` : `$${discountAmount}`;
  const amountLabel = amount ? `${amount} off ` : '';
  // Retrieves the text to display for the coupon components.
  const couponComponentsText = getCouponComponentsText(components).join(', ');
  const materialLabel = !isMaterialInfoHidden && couponComponentsText ? `${couponComponentsText} ` : '';
  const orderLimit = usageLimit?.orderLimit;
  const orderLimitPlural = orderLimit === 1 ? '' : 's';
  const usageLimitLabel = orderLimit ? ` (Up to ${orderLimit} unit${orderLimitPlural}) ` : '';
  // For expired coupons, shows the expired label.
  // For valid coupons, shows the expiration date and time.
  const expirationLabel = isCouponExpired(coupon.expirationDate)
    ? `- ${COUPON_EXPIRED_LABEL}`
    : `- Exp: ${getDateAndTimeInShortFormat(expirationDate)}`;

  return `${code}: ${amountLabel}${materialLabel}${usageLimitLabel}${expirationLabel}`;
};

/**
 * Gets the React component that should display extra data for coupons.
 */
export const getCouponTooltipComponent = (coupon: Coupon): React.ReactNode => {
  const couponTooltipText = getCouponText(coupon, true);
  const componentsText = getCouponComponentsText(coupon.components);

  const componentsChildren = DisplayDiscountItemTooltip({
    text: couponTooltipText,
    items: componentsText,
    className: 'right-0',
  });
  return componentsChildren;
};

/**
 * builds a coupon item from coupon and selected case id
 */
export const getDiscountCouponItem = (coupon: Coupon, selectedCaseId: string) => {
  const { code: discountCode, discountAmount, status } = coupon || {};
  const result: DisplayDiscountItemType = {
    caseId: selectedCaseId,
    discountCode,
    discountAmount,
    discountText: getCouponText(coupon),
    tooltipComponent: getCouponTooltipComponent(coupon),
  };
  if (status) {
    result.status = status;
  }
  return result;
};

/**
 * builder function for build shipping option form package and service name
 */
export const getDoctorPickupCarrierItem = (packageName: PackageType, serviceName: string): ShippingOption => {
  return {
    __typename: 'ShippingOption',
    carrier: ShippingCarrier.DoctorPickup,
    cost: 0,
    isSaturdayDelivery: false,
    package: packageName,
    charges: [],
    rateId: '',
    requiresSignature: false,
    service: serviceName,
    shipmentId: '',
  };
};

export const findCaseAttributesByAttributeProperty = (
  caseItems:
    | RemoveTypename<BaseOrderItem>
    | RemoveTypename<BaseOrderItem>[]
    | RemoveTypename<SearchedOrderItem>
    | RemoveTypename<SearchedOrderItem>[]
    | RemoveTypename<RelatedOrderItem>
    | RemoveTypename<RelatedRmaOrderItem>
    | undefined,
  attributeValue: string,
  attributeProperty: keyof Pick<OrderProductAttribute, 'name' | 'type'> = 'type'
) => {
  if (!caseItems) return [];
  const items = Array.isArray(caseItems) ? caseItems : [caseItems];
  return items.flatMap(item => item.attributes).filter(item => item[attributeProperty] === attributeValue);
};

/**
 * Retrieves a specific add-on or service from a case item based on the provided property value.
 *
 * @param caseItems - The case items containing add-ons or services.
 * @param addOnValue - The value to match against the 'name' property of add-ons or services.
 * @param field - The field specifying whether to search in 'addOns' or 'services'.
 *
 * @returns The matched add-on or service, or null if not found.
 */
export const getCaseAddOnOrServiceByProperty = (
  caseItems: CreatedOrderItem,
  addOnValue: string,
  field: 'addOns' | 'services'
) => caseItems[field]?.find(item => item.name === addOnValue) || null;

/**
 * retrieve an attribute from case item using attribute type
 * @param caseItem - case item with attributes
 * @param attributeType - type of attribute
 */
export const getCaseItemAttributeByAttributeType = (
  caseItem: RemoveTypename<BaseOrderItem> | SearchedOrderItem | undefined,
  attributeType: AttributeType
) => {
  const attributes = findCaseAttributesByAttributeProperty(caseItem, attributeType);
  return attributes.length ? attributes[0] : null;
};

/**
 * retrieve an attribute from case item using attribute name
 * @param caseItem - case item with attributes
 * @param attributeName - name of attribute
 */
export const getCaseItemAttributeByAttributeName = (
  caseItem: RemoveTypename<BaseOrderItem> | SearchedOrderItem | RelatedOrderItem | RelatedRmaOrderItem | undefined,
  attributeName: AttributeName
) => {
  const attributes = findCaseAttributesByAttributeProperty(caseItem, attributeName, 'name');
  return attributes.length ? attributes[0] : null;
};

/**
 * Returns true if the metal alloy attribute selection is valid and ready to be invoiced.
 * @param metalAlloyAttributes - The metal alloy attributes to verify selections for.
 * @returns true if the metal alloy attribute selection is valid and ready to be invoiced.
 */
export const hasValidSelectionsForMetalAlloys = (
  metalAlloyAttributes: OrderProductAttribute[] | RemoveTypename<OrderProductAttribute>[]
) => {
  return metalAlloyAttributes.every(item => item.name && item.name !== ALLOY_NO_VALUE && +item.value > 0);
};

/**
 * Filter out order that has Return Type = Adjust
 * @param orderItems - products in the order
 * @returns - a list of products without ReturnType = Adjust
 */
const filterAdjustRmaOrderItems = (
  orderItems: (BaseOrderItem[] & OrderItem[]) | (BaseOrderItem[] & RmaOrderItem[])
) => {
  return orderItems.filter(item => {
    // Check if we have a returnType in our item
    const isRmaOrderItem = 'returnType' in item;
    // Filter out any orderItems with return type is Adjust
    return !isRmaOrderItem || item.returnType !== ReturnType.Adjust;
  });
};

/**
 * As per LMS1-7690, RMA adjust orders should not be prevented from invoicing if no alloy type/weight is present
 * https://glidewell.atlassian.net/browse/LMS1-7690
 * @param caseDetails - case detail to be validated
 * @returns true if the metal alloy attribute selection is valid and ready to be invoiced.
 */
export const validateMetalAlloyAttributes = (
  caseItems: (BaseOrderItem[] & OrderItem[]) | (BaseOrderItem[] & RmaOrderItem[])
) => {
  const caseItemsToBeValidated = filterAdjustRmaOrderItems(caseItems);
  const metalAlloyAttributes = findCaseAttributesByAttributeProperty(caseItemsToBeValidated, AttributeType.Alloy);
  if (!metalAlloyAttributes.length) return true;
  // If no attribute type is available, the UI will default to sending "No Value".
  return hasValidSelectionsForMetalAlloys(metalAlloyAttributes);
};

/**
 * computes user-friendly product title to show in UI
 * @param product - represents order being created
 */
export const getCaseProductTitle = (product: CreatedOrderItem) => {
  let productTitle = product.productName;

  const shadeAndToothStringValue = getShadeAndToothStringValue(product);

  if (shadeAndToothStringValue) {
    productTitle += ` ${shadeAndToothStringValue}`;
  }

  return productTitle;
};

/**
 * @returns a default charge object
 */
export const getDefaultCharge = (): Charge => {
  return {
    __typename: 'Charge',
    currencies: [],
    isTaxable: false,
    service: '',
    shippingChargeId: '',
  };
};

/**
 * @returns default shipping details
 * @param deliveryMethod - delivery method
 * @param packageType - package type
 */
export const getDefaultShipping = (
  deliveryMethod: DeliveryMethod,
  packageType: InvoicingDetailContext['invoiceShippingInput']['packageType'] = PackageType.Box
): InvoiceShipping => {
  return {
    deliveryMethod,
    packageType,
  };
};

/**
 * checks if at-least one enclosed item has a metal articulator in list of created order items
 * @param enclosedItems - enclosed items
 * @returns true if given list of enclosed items has a metal articulator, else false
 */
export const someEnclosedItemsHaveMetalArticulator = (enclosedItems: Pick<OrderEnclosedItem, 'itemCode'>[]) => {
  return enclosedItems.some(item => item.itemCode.toLowerCase().includes(ARTICULATOR.toLowerCase()));
};

/**
 * checks if at-least one order has a metal articulator in list of created order items
 * @param cases - created order items
 * @returns true if given list of orders has a metal articulator, else false
 */
export const someCasesHaveMetalArticulator = (cases: Pick<CreatedOrder, 'enclosedItems'>[]) => {
  return cases.some(thisCase => {
    return someEnclosedItemsHaveMetalArticulator(thisCase.enclosedItems);
  });
};

/**
 * Validates order items special order parts and returns an error message upon failure.
 * @param orderItems - order items which needs to be validated
 * @returns an error message for special order parts, if the validation failed.
 */
export const validateOrderItemsSpecialOrderParts = (
  orderItems: Array<{
    attributes: OrderItem['attributes'];
    addOns: OrderItem['addOns'];
    services: OrderItem['services'];
  }>
): string => {
  let specialOrderPartsErrorMessage = '';
  orderItems.forEach(item => {
    const { addOns = [], services = [] } = item;
    const specialOrderPartsAttribute = services.find(att => att.name === AttributeType.SpecialOrderParts);
    if (specialOrderPartsAttribute) {
      const specialOrderPartsAmount = addOns.find(addOn => addOn.name === SpecialOrderParts.Amount);
      const specialOrderPartsNotes = addOns.find(addOn => addOn.name === SpecialOrderParts.Notes);
      if (!specialOrderPartsAmount?.value) {
        specialOrderPartsErrorMessage = 'Please provide a cost amount for the implant part add-on.';
      } else if (!specialOrderPartsNotes?.value) {
        specialOrderPartsErrorMessage = 'Please provide notes for the implant part add-on.';
      }
    }
  });
  return specialOrderPartsErrorMessage;
};

/**
 * Returns whether we need to treat this bundle as a split bundle for the given order number.
 *
 * @param bundles - the bundles to check for split bundle cases.
 * @param orderNumber - the order number to validate the case is not detached.
 *
 * @returns true if bundle needs split treatment, else false
 */
export const isSplitBundle = (bundles: OrderBundle, orderNumber: string): boolean => {
  const { splitBundle } = bundles || {};

  return (
    !!splitBundle?.bundledOrderNumbers.some(order => order.orderNumber !== orderNumber) &&
    !splitBundle?.detachedOrderNumbers.some(order => order.orderNumber === orderNumber)
  );
};

/**
 * Navigate to invoice order lookup page if order is part of split bundle.
 * Else, navigate to invoice page.
 * @param order - order to invoice.
 * @param navigate - navigate function.
 */
export const navigateToInvoiceHelper = (order: CreatedOrder | undefined, navigate: NavigateFunction) => {
  if (order) {
    if (order.bundles && isSplitBundle(order.bundles, order.orderNumber)) {
      navigate(`${ROUTES.INVOICE_ORDER_LOOKUP}?${BUNDLE_CASE_NUMBER_KEY}=${order.orderNumber}`);
    } else {
      navigate(getInvoiceCreatePath(order.orderNumber));
    }
  }
};

/**
 * Filters the enclosed items by shipping type.
 * @param enclosedItems - The array of enclosed items.
 * @param shippingType - The shipping type to filter by.
 * @returns The filtered array of enclosed items.
 */
export const filterEnclosedItemsByShippingType = (
  enclosedItems: OrderEnclosedItem[],
  shippingType: EnclosedItemCategory
) => {
  return enclosedItems.filter(item => item.shippingType === shippingType);
};
