import { DoNotShip, OrderStatus } from 'API';
import { getLocaleTimeZoneDateWithFormat } from 'shared/helpers/date.helper';

export const PACIFIC_TIMEZONE_CODE = 'America/Los_Angeles';
export const ARTICULATOR = 'Articulator';

interface DoNotShipToOfficeClosedMappingI {
  [dayOfWeek: string]: string;
}

export const DoNotShipToOfficeClosedMapping: DoNotShipToOfficeClosedMappingI = {
  Monday: 'Tuesday',
  Tuesday: 'Wednesday',
  Wednesday: 'Thursday',
  Thursday: 'Friday',
  Friday: 'Monday',
  Saturday: 'Monday',
  Sunday: 'Monday',
};

/**
 * constructs a Do Not ship message in regular setup
 *
 * @param day - day of week
 *
 * @returns constructed do not ship message
 */
export const getDoNotShipPermanentMessage = (day: string) => {
  const officeClosedOnDay = DoNotShipToOfficeClosedMapping[day];
  const officeClosedMessage = officeClosedOnDay ? ` Office closed on ${officeClosedOnDay}.` : '';
  return `Do not ship ${day}s.${officeClosedMessage}`;
};

/**
 * constructs a Do Not ship message in a temporary off period
 *
 * @param start - start of temporary off
 * @param end - end date of temporary off
 * @param day - day of week
 *
 * @returns constructed temporary Do Not ship message
 */
export const getDoNotShipTemporaryMessage = (start: string, end: string, day?: DoNotShip['day']) => {
  const startDate = getLocaleTimeZoneDateWithFormat({ date: start });
  const endDate = getLocaleTimeZoneDateWithFormat({ date: end });
  return `Do not ship ${day ? `${day}s ` : ''}${startDate} - ${endDate}`;
};

/**
 * constructs a Do Not Shipping message
 *
 * @param doNotShip - input object holding shipping like day of week, start and end dates
 *
 * @returns user friendly message
 */
export const getDoNotShipMessage = (doNotShip: DoNotShip) => {
  const { day, start, end } = doNotShip;
  // if current day fall between start date and end date
  if (end) {
    return getDoNotShipTemporaryMessage(start, end, day);
    // if no end date
  } else if (day && !end) {
    return getDoNotShipPermanentMessage(day);
  }
};

/**
 * list of invoice statuses which can no longer be treated as valid
 */
export const INVALID_INVOICE_STATUSES = [
  OrderStatus.Deleted,
  OrderStatus.Invoiced,
  OrderStatus.OnHold,
  OrderStatus.CallCenter,
  OrderStatus.CreditPending,
  OrderStatus.Credited,
];

export const caseInvoiceCompleteStatuses = [OrderStatus.Invoiced, OrderStatus.Shipped];
export const caseInvoicedStatuses = [...caseInvoiceCompleteStatuses, OrderStatus.InvoicePending];
export const caseNonLinkableStatuses = [
  OrderStatus.InLab,
  OrderStatus.OnHold,
  OrderStatus.CallCenter,
  OrderStatus.CreditPending,
  OrderStatus.Credited,
];
export const caseStatusesForRelatedSearch = [...caseInvoicedStatuses, ...caseNonLinkableStatuses];
