import { ArchiveBoxIcon, NoSymbolIcon, TrashIcon } from '@heroicons/react/24/outline';
import { ManufacturingLocation, OrderStatus } from 'API';
import _ from 'lodash';
import { AlertModalContext } from 'providers/AlertModalProvider';
import { useAuth } from 'providers/AuthProvider';
import { useCaseDetail } from 'providers/CaseDetailModuleProvider';
import { useManufacturingLocation } from 'providers/ManufacturingLocationProvider';
import { useToast } from 'providers/ToastProvider';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { InvoiceAccount } from 'shared/api/invoice.api';
import { updateOrderStatus } from 'shared/api/order.api';
import { CancelActivity, kebabMenu } from 'shared/constants/case-detail.constants';
import { PERMISSION_DENIED, PERMISSION_DENIED_TEXT } from 'shared/constants/role-based-access-control';
import { ToastNotificationType } from 'shared/enums';
import { AnalyticsEventName } from 'shared/enums/analytics';
import { ACTIONS } from 'shared/enums/permission';
import { ACCOUNT_CREDIT_HOLD_ERROR_NAME } from 'shared/helpers/error-helper';
import { navigateToInvoiceHelper } from 'shared/helpers/invoice/invoice.helper';
import { validateCaseDetailForInvoicing } from 'shared/helpers/util.helper';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { useRoleBasedAccessControl } from 'shared/hooks/useRoleBasedAccessControl';
import { AnalyticsService } from 'shared/services/analytics.service';
import { getDateAndTimeInLongFormat } from 'shared/utils';
import CancelCaseModal from './CancelCaseModal/CancelCaseModal';
import { DeleteCaseModal } from './DeleteCaseModal/DeleteCaseModal';
import { HoldCaseModal } from './HoldCaseModal/HoldCaseModal';
import { UnInvoiceModal } from './UnInvoiceModal/UnInvoiceModal';

interface CaseDetailStatusContentProps {
  menuName: string;
  setMenuName: (menuName: string) => void;
  orderNumber: string;
  originFacilityId?: number | null;
  account: InvoiceAccount | null;
}

export const CaseDetailStatusContent: FC<CaseDetailStatusContentProps> = ({
  menuName,
  setMenuName,
  orderNumber,
  originFacilityId,
  account,
}) => {
  const { caseDetails, setCaseDetails } = useCaseDetail();
  const [showHoldCaseModal, setShowHoldCaseModal] = useState(false);
  const [showCancelCaseModal, setShowCancelCaseModal] = useState(false);
  const [showDeleteCaseModal, setShowDeleteCaseModal] = useState(false);
  const [showUnInvoiceCaseModal, setShowUnInvoiceCaseModal] = useState(false);
  const [manufacturingLocation, setManufacturingLocation] = useState<ManufacturingLocation>();
  const { getManufacturingLocation } = useManufacturingLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const { user } = useAuth();
  const { fetcher: updateOrderStatusFetcher } = useLazyQueryFetcher(updateOrderStatus);

  const cancelCasePermission = useRoleBasedAccessControl(ACTIONS.CANCEL_CASE);
  const deleteCasePermission = useRoleBasedAccessControl(ACTIONS.DELETE_CASE);
  const uninvoiceCasePermission = useRoleBasedAccessControl(ACTIONS.UNINVOICE);
  const invoiceCasePermission = useRoleBasedAccessControl(ACTIONS.INVOICE_CASE);
  const alert = useContext(AlertModalContext);

  useEffect(() => {
    const location = getManufacturingLocation(originFacilityId ?? 0);
    if (location) {
      setManufacturingLocation(location);
    }
  }, [getManufacturingLocation, originFacilityId]);

  const handleContinueCase = useCallback(async () => {
    try {
      const result = await updateOrderStatusFetcher({
        orderNumber,
        orderStatus: OrderStatus.InLab,
        updatedBy: user?.displayName ?? '',
      });

      AnalyticsService.track(AnalyticsEventName.CaseContinued, {
        caseNumber: orderNumber,
        caseStatus: OrderStatus.InLab,
      });

      if (caseDetails) {
        setMenuName('');
        setCaseDetails({ ...caseDetails, status: result.status, statusReason: result.statusReason });
      }
      toast.notify('Case status has been updated successfully', ToastNotificationType.Success);
    } catch (err) {
      const error = err as Error;
      toast.notify(error.message, ToastNotificationType.Error);
    }
  }, [updateOrderStatusFetcher, orderNumber, user?.displayName, caseDetails, toast, setMenuName, setCaseDetails]);

  useEffect(() => {
    if (menuName === kebabMenu.holdCase) {
      setShowHoldCaseModal(true);
    }

    if (menuName === kebabMenu.cancelCase) {
      if (cancelCasePermission.allowUpdate) {
        setShowCancelCaseModal(true);
      } else {
        setMenuName('');
        alert.show(PERMISSION_DENIED, PERMISSION_DENIED_TEXT);
      }
    }

    if (menuName === kebabMenu.deleteCase) {
      if (deleteCasePermission.allowDelete) {
        setShowDeleteCaseModal(true);
      } else {
        setMenuName('');
        alert.show(PERMISSION_DENIED, PERMISSION_DENIED_TEXT);
      }
    }

    if (menuName === kebabMenu.unInvoice) {
      if (uninvoiceCasePermission.allowUpdate) {
        setShowUnInvoiceCaseModal(true);
      } else {
        setMenuName('');
        alert.show(PERMISSION_DENIED, PERMISSION_DENIED_TEXT);
      }
    }

    if (menuName === kebabMenu.invoice) {
      if (invoiceCasePermission.allowUpdate) {
        try {
          validateCaseDetailForInvoicing(caseDetails, account);
          navigateToInvoiceHelper(caseDetails, navigate);
        } catch (err) {
          setMenuName('');
          const error = err as Error;
          if (error?.name === ACCOUNT_CREDIT_HOLD_ERROR_NAME) {
            const msg = {
              title: 'Invoicing is not allowed because Credit Hold account status',
              message: `Account # ${account?.billingAccountId}\n\nThis case cannot be invoiced because the account is on Credit Hold. Place on hold for review`,
            };
            alert.show(msg.title, msg.message);
          } else {
            toast.notify(error.message, ToastNotificationType.Error);
          }
        }
      } else {
        setMenuName('');
        alert.show(PERMISSION_DENIED, PERMISSION_DENIED_TEXT);
      }
    }

    if (menuName === kebabMenu.continueCase) {
      handleContinueCase();
    }
  }, [
    menuName,
    navigate,
    orderNumber,
    caseDetails,
    toast,
    account,
    handleContinueCase,
    cancelCasePermission.allowUpdate,
    alert,
    deleteCasePermission.allowDelete,
    uninvoiceCasePermission.allowUpdate,
    invoiceCasePermission.allowUpdate,
    setMenuName,
  ]);

  const onCloseModal = () => {
    setMenuName('');

    if (menuName === kebabMenu.holdCase) {
      setShowHoldCaseModal(false);
    }

    if (menuName === kebabMenu.cancelCase) {
      setShowCancelCaseModal(false);
    }

    if (menuName === kebabMenu.deleteCase) {
      setShowDeleteCaseModal(false);
    }

    if (menuName === kebabMenu.unInvoice) {
      setShowUnInvoiceCaseModal(false);
    }
  };
  const onConfirmUnInvoice = (success?: boolean) => {
    if (success) {
      const newOrder = _.cloneDeep(caseDetails);
      if (newOrder) {
        newOrder.status = OrderStatus.UnInvoiced;
        setCaseDetails(newOrder);
      }
    }
  };

  const isCancelDiscard =
    caseDetails && caseDetails.status === OrderStatus.Cancelled && caseDetails.statusReason === CancelActivity.Discard;

  const originatingSystem = caseDetails?.originatingSystem;

  return (
    <>
      {caseDetails && (
        <div>
          {caseDetails.status === OrderStatus.Deleted && (
            <div className="flex p-4 bg-gray-100 items-center gap-2">
              <TrashIcon className="w-4 h-4 text-gray-600" />
              <p className="text-sm text-gray-800">
                This case was deleted by {caseDetails.updatedBy} on{' '}
                {caseDetails.updatedDate && getDateAndTimeInLongFormat(new Date(caseDetails.updatedDate))}.
              </p>
            </div>
          )}

          {caseDetails.status === OrderStatus.Cancelled && (
            <div className="flex p-4 bg-gray-100 items-center gap-2">
              <NoSymbolIcon className="w-4 h-4 text-gray-600" />
              <p className="text-sm text-gray-800">
                {`This case was canceled${
                  isCancelDiscard
                    ? ' and discarded. Discarded cases are disabled and cannot be reactivated or invoiced.'
                    : '. Reactivate it to make any changes.'
                }`}
              </p>
            </div>
          )}

          {caseDetails.originatingSystem && originatingSystem !== 'LMS' && (
            <div className="flex p-4 bg-orange-50 items-center gap-2">
              <ArchiveBoxIcon className="w-4 h-4 text-orange-700" />
              <p className="text-orange-700 text-sm font-medium">
                {`Legacy Case from ${originatingSystem} - Some information may be incomplete or missing`}
              </p>
            </div>
          )}

          <UnInvoiceModal
            showModal={showUnInvoiceCaseModal}
            onCloseModal={onCloseModal}
            timezone={manufacturingLocation?.manufacturingFacilityTimeZoneId || ''}
            onConfirmUnInvoice={onConfirmUnInvoice}
            accountNumber={account?.billingAccountId || ''}
          />
          <HoldCaseModal showModal={showHoldCaseModal} onCloseModal={onCloseModal} />
          <CancelCaseModal showModal={showCancelCaseModal} onCloseModal={onCloseModal} />
          <DeleteCaseModal showModal={showDeleteCaseModal} onCloseModal={onCloseModal} />
        </div>
      )}
    </>
  );
};
